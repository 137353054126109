import { Component, Injector, OnInit } from '@angular/core';
import { MessagingService } from '@app/@core/messaging';
import { DashboardWidgetsServiceProxy } from '@shared/service-proxies/service-proxies';
import { result } from 'lodash';
import { DashboardCustomizationConst } from '../../DashboardCustomizationConsts';
import { WidgetComponentBase } from "../WidgetComponentBase";

@Component({
    selector: 'app-WidgetSubscriptionsStatus-Status',
    templateUrl: './WidgetSubscriptionsStatus.html',
    styleUrls: ['./WidgetSubscriptionsStatus.css']
})
export class WidgetSubscriptionsStatusComponent extends WidgetComponentBase implements OnInit {
    single: any[];
    multi: any[];
    view: any[] = [700, 400];
    startdate: any;
    Enddate: any;
    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = false;
    showXAxisLabel = true;
    xAxisLabel = 'Subscriptions Status';
    showYAxisLabel = true;
    yAxisLabel = 'Last 7 Days';
    colorScheme = {
        domain: ['#A10A28', '#5AA454',]
    };


    constructor(injector: Injector, private widgetservice: DashboardWidgetsServiceProxy, private messageservice: MessagingService) {
        super(injector);
    }

    onSelect(event) {
        console.log(event);
    }
    ngOnInit() {

        this.GetSubscriptionStatus()
    }
    GetSubscriptionStatus() {
        this.widgetservice.getSubscriptionStatus()
            .subscribe(data => {
                this.single = data.map(res => ({ name: res.status, series: res.valuePairs }));
            });


    }

}


