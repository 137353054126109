import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-pending-changes-modal',
    templateUrl: './pending-changes-modal.component.html',
    styleUrls: ['./pending-changes-modal.component.css']
})
export class PendingChangesModalComponent implements OnInit {

    constructor(public dialogRef: MatDialogRef<PendingChangesModalComponent>) { }

    ngOnInit() {
    }
    onNoClick(): void {
        this.dialogRef.close();
    }
}
