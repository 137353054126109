import { OAuthService } from 'angular-oauth2-oidc';
import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { authCodeFlowConfig } from '@shared/utils/authService';
 
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(private cookieService: CookieService,private authservice :OAuthService) {

    
   }

  ngOnInit(): void {
    this.clearAppData();
  }

  private clearAppData(): void {
    
    this.authservice.configure(authCodeFlowConfig);


     localStorage.clear();
    sessionStorage.clear();
    this.cookieService.deleteAll();

    
    this.authservice.logOut(false);
  }
}
 