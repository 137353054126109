import { IExtractedDocumentView, IHumanTask } from "@shared/service-proxies/service-proxies";
import { ITaskSorterDto } from '../../../shared/service-proxies/service-proxies';

export interface ITask {
    caseRecordName: string | undefined;
    humanTask: IHumanTask;
    taskQueueName: string | undefined;
    organizationUnitDisplayName: string | undefined;
    userName: string | undefined;
}

// export interface IHumanTask {
//     name: string | undefined;
//     description: string | undefined;
//     caseNumber: string | undefined;
//     caseRecordId: string | undefined;
//     caseType: string | undefined;
//     dueOn: moment.Moment | undefined;
//     status: string | undefined;
//     assignedTo: string | undefined;
//     taskQueueId: string | undefined;
//     documentSessionID: string | undefined;
//     organizationUnitId: number | undefined;
//     userId: number | undefined;
//     createdOn: moment.Moment | undefined;
//     createdBy: number | undefined;
//     lastModificationTime: moment.Moment | undefined;
//     isSelected: boolean;
//     taskQueueName: string | undefined;
//     organizationUnitDisplayName: string | undefined;
//     userName: string | undefined;
//     id: string;
// }

export interface IPage {
    document: number;
    file: number;
    fileID: string;
}

export interface IProcessedDocument extends IExtractedDocumentView {
    // order: number;
    // tenantId: number;
    // name: string;
    // fileID: string;
    // numberOfPages: number;
    // qualityScore: number;
    // pageRangeLabel: string;
    // isVerified: boolean;
    // hasErrors: boolean;
    // documentClassificationType: string;
    // isExtractedContentStructured: boolean;
    // schema: string;
    // isSkipped: boolean;
    // sessionID: string;
    // extractedFileId: string;
    // organizationUnitId: number;
    // documentId: string;
    // isReadOnly: boolean;
    // version: number;
    // entityId: string;
    selected: boolean;
    // pages: IPage[];
    // id: string;
}


export interface IFormLable {

    Lable: string;
    fieldID?: string;
    BorderColor: string;
    BackGroundColor: string;
    Key: string;
    singleUsage?: boolean;
    Sensitivity?: string;
    cordinates?: ILableCordinates;
    ParentGroup?: string;
    index?: number;
    isHidden: boolean;
    labels?: IFormLable[];
}
export interface INEXTTASK {
    name?: any;
    description?: any;
    caseNumber?: any;
    caseType?: any;
    dueOn?: any;
    status?: any;
    assignedTo: string;
    taskQueueId?: any;
    documentSessionID: string;
    organizationUnitId?: any;
    userId?: any;
    createdOn?: any;
    createdBy?: any;
    lastModificationTime?: any;
    caseRecordId?: any;
    isSelected: boolean;
    taskQueueName: string;
    organizationUnitDisplayName?: any;
    userName?: any;
    id: string;
}
export interface IAttachments {
    id: string;
    name: string;
    sizeInBytes: number;
}

export interface IFormPage {
    pageNumber: number;
    huMoments: string;
}

export interface IForm {
    name: string;
    edition: string;
    pages: number;
    templateLibrary: string;
    entityId: string;
    formPages: IFormPage[];
    jsonSchema: string;
    outputMapping: string;
    isUnstructured: boolean;
    id: string;
}


export interface IFormsLibrary {
    templateLibrary: string;
    forms: IForm[];
}

export interface IDocumentDetail {
    content: string;
    isContentReadOnly: boolean;
    contentVersion: number;
    schema: string;
    hasErrors: boolean;
    isVerified: boolean;
    entityId: string;
}
export interface ISchema {
    Id: number | undefined;
    Key: string | undefined;
    Visibility: string | undefined;
    Type: string | undefined
    Title: string | undefined
    isDone: boolean | undefined
    Importance: string | undefined
    Sensitivity: string | undefined
    SortOrder: number | undefined
    Required: boolean | undefined
    Pattern: string | undefined;
    Validation: IValidation | undefined;
    MaximumLength: number | undefined;
    MinimumLength: number | undefined;
    MaximumItems: number | undefined;
    MinimumItems: number | undefined;
    Description: string | undefined;
    selected: boolean | undefined;
    pageNumber: number | undefined;
    recognitionType: string | undefined;
    isCaseLookup: string | undefined;
    hasCaseFieldMapping: string | undefined;
    caseFieldID: string | undefined;
    items: ISchema[];
}
export interface IValidation {
    Id: string | undefined
    Name: string | undefined
    RegularExpression: string | undefined
    IsCustomValidationstring: boolean | undefined
    IsLookupValidation: boolean | undefined
    LookupName: string | undefined
    IsRegulatExpression: boolean | undefined
    CustomValidationType: string | undefined
}
export interface ILookupViewModel {
    Id: string;
    Name: string;
    SequenceOrder: number;
    LookupCode: string;
    LookupValue: string;
    LookupDescription: string;

}

export interface ILookupForView {
    lookup: ILookupViewModel;
}
export interface IDocumentRequest {
    content: string
    isVerified: boolean
    hasErrors: boolean
    schema: string
    entityId: string
}

export interface IFieldMapping {
    rawKey: string;
    key: string;
    page: number;
    value: string;
    confidence: number;
    top: number;
    left: number;
    height: number;
    width: number;
}

export class PagesNode {
    public document: number;
    public file: number
    constructor(

    ) {
        this.document = 0;
        this.file = 0;
    }
}

export interface ISelectionRectangle {
    startingIndex: number;
    textLenght: number;
    borderColor: string;
    backgroundColor: string;
    left: number;
    top: number;
    width: number;
    label: string;
}
export interface ITextSelectEvent {
    text: string;
    viewportRectangle: ISelectionRectangle | null;
}

export class ICoordinates {
    public startIndex: string;
    public lastIndex: string;
    public label: string;
    public text: string;
    public bgColor?: string;
    public borderColor?: string;
    public turnID?: number;
}


export class IDocumentSorter implements ITaskSorterDto {
    pageID: string;
    fileName: string;
    pageNumber: number;
    classification: string;
    classificationPage: number;
    imageUrl: string;
    thumbnailImageUrl: string;
    originalTaskNumber: number;
    originalImagePosition: number;
    newImagePosition: number;
    extractedFileId: string;
    fileId: string;
    // pageID?: string;
    // fileName?: string;
    // pageNumber?: number;
    // classification?: string;
    // classificationPage?: number;
    // imageUrl?: string;
    // fullImageUrl?: string;

    // thumbnailImageUrl?: string;
    // originalTaskNumber?: number;
    // originalImagePosition?: number;
    // newImagePosition?: number;
    loading?: boolean | null = false;
    newTaskNumber?: number;
    isDeleted?: boolean = false;


    Imagepages?: [

    ];

}


export class ITaskSorter {
    TaskName: string;
    TaskID?: number;
    isAboundant?: boolean = false;
    pages: IDocumentSorter[];
    classification?: string;
    constructor() {
        this.pages = new Array<IDocumentSorter>();

    }

}

export class IPostTaskSorter {

    originalTaskNumber?: number;
    originalImagePosition?: number;

    newTaskNumber: any;
    newImagePosition?: number;
    pageID?: string;
    constructor() {


    }

}

export class TaskSorterImage {
    pageID: string;
    originalImagePosition: number;
    newImagePosition: number;
    constructor() { }
}

export class Task {
    taskNumber: number;
    taskSorterImages: TaskSorterImage[];
    constructor() {
        this.taskSorterImages = new Array<TaskSorterImage>();
    }
}

export class SortedDocument {
    originalTask: string;
    changeCurrentTaskStatus: string;
    queueId: string;
    dueOn: string;
    taskNameTemplate: string;
    tasks: Task[];

    constructor() {
        this.taskNameTemplate = '';
        this.tasks = new Array<Task>();
    }
}
export interface IJobRequestStats {
    TotalFiles: number,
    PassedFiles: number,
    FailedFiles: number,
    ErrorFiles: number,
    StartTime: Date,
    EndTime: Date,
    ImportTime: number,
    BatchSizeInKb: number,
    IsDocumentVerified: boolean
}
export interface IStatistics_Result {
    tenantId?: number;
    sessionID?: string;
    jobStatusID?: string;
    totalDocuments?: number;
    totalProcessedDocuments?: number;
    totalErrors?: number;
    totalProcessedDoc?: number;
    totalProcessedForms?: number;
    totalProcessedPng?: number;
    totalProcessedPdf?: number;
    totalProcessedJpg?: number;
    messageToNotify?: string;
    statusAsOnUTC?: Date;
    status?: string;
    totalFiles?: number;
    passedFiles?: number;
    failedFiles?: number;
    errorFiles?: number;
    costInCents?: number;
    isVerified?: boolean;
    totalOcrCalls?: number;
    totalNERCalls?: number;
    startedOnUTC?: Date;
    completedOnUTC?: Date;
    enqueuedOnUTC?: Date;
    sizeInKB?: number;
    organizationUnitId?: number;
    organizationUnitFk?: IOrganizationUnitFk;
    id?: string;
}
export interface IOrganizationUnitFk {
    tenantId?: number;
    parentId?: number;
    code?: string;
    displayName?: string;
    children?: any[];
    isDeleted?: boolean;
    deleterUserId?: number;
    deletionTime?: Date;
    lastModificationTime?: Date;
    lastModifierUserId?: number;
    creationTime?: Date;
    creatorUserId?: number;
    id?: number;
}
export interface IStatistics {
    result?: IStatistics_Result[];
}

export class IHeadersValue {
    header: ICaseRecordFieldsDto;
    value: any;
    constructor() { }
}

export interface ICaseRecordFieldsDto {
    fieldName: string | undefined;
    text: string | undefined;
    dataType: string | undefined;
    uiControl: string | undefined;
    description: string | undefined;
    isHidden: boolean;
    isLookup: boolean;
    lookupType: string | undefined;
    displayOrder: number;
    isSearchable: boolean;
    caseRecordFieldId: string;
    caseFieldName: string | undefined;
    id: string;
}
export interface ILableCordinates {
    confidence?: number;
    height?: number;
    left?: number;
    page?: number;
    top?: number;
    value?: string;
    width?: number;
    //items?: ILableCordinates[]
}

export interface ITaskQueue {
    name: string;
    hasSLA: boolean;
    workingDays: string;
    slaHours: number;
    slaDays: number;
    organizationUnitId: number;
    id: string;
}

export interface IQueue {
    taskQueue: ITaskQueue;
    organizationUnitDisplayName: string;
}

export interface ITaskQueueItems {
    totalCount: number;
    items: IQueue[];
}

export interface IUser {
    id: number;
    displayName: string;
}

export interface ILookup {
    name: string;
    sequenceOrder: number;
    lookupCode: string;
    lookupValue: string;
    lookupDescription: string;
    id: string;
}

export interface ILookup {
    lookup: ILookup;
}

export interface ILookupitems {
    totalCount: number;
    items: ILookup[];
}
export interface IHumanTaskItems {
    humanTask: IHumanTask;
    taskQueueName: string;
    organizationUnitDisplayName: string;
    userName: string;
    caseRecordName: string;
}
// export interface IHumanTask {
//     name: string | undefined;
//     description: string | undefined;
//     caseNumber: string | undefined;
//     caseType: string | undefined;
//     dueOn: moment.Moment | undefined;
//     status: string | undefined;
//     documentSessionID: string;
//     assignedTo: string | undefined;
//     isInvoice: boolean;
//     taskQueueId: string | undefined;
//     organizationUnitId: number | undefined;
//     userId: number | undefined;
//     caseRecordId: string | undefined;
//     id: string | undefined;
// }
// export interface IHumanTask {
//     name: string;
//     description: string;
//     caseNumber: string;
//     caseType: string;
//     dueOn: moment.Moment | undefined;
//     status: string;
//     assignedTo: string;
//     taskQueueId: string;// extra
//     documentSessionID: string;
//     isInvoice: boolean;
//     organizationUnitId: number;
//     userId: number;
//     createdOn: moment.Moment | undefined;
//     createdBy: number;
//     lastModificationTime: moment.Moment | undefined;
//     caseRecordId: string;
//     isSelected: boolean;
//     taskQueueName: string;
//     organizationUnitDisplayName: string;
//     userName: string;
//     id: string;
// }

export interface ITaskItem {
    totalCount: number;
    items: ITask[];
}
export interface IDeleteItem {
    id: number;
    displayName: string;
}

export interface IDeleteItem {
    totalCount: number;
    items: IDeleteItem[];
}
export interface IEmail {
    subject?: string;
    body?: string;
    priority: number;
    recievedOnUTC: Date;
    sentOnUTC: Date;
    mailBox: string;
    toJSON: string;
    fromJson: string;
    ccJson: string;
    humanTaskId: string;
    id: string;
    shortName: string
}
export interface ITaskEmail {
    humanTaskName: string;
    email: IEmail
}


export class TaskGridFilters {
    taskNameFilter: string
    nameFilter: string
    fileNameFilter: string
    caseNumberFilter: string
    caseTypeFilter: string
    caseRecordNameFilter: string
    queueFilter: any
    assignToFilter: any
    statusFilter: any
    organizationUnitFilter: any
    //  minDateRangeFilter:Date,
    //maxDateRangeFilter:Date,
    minDueOnFilter: string;
    maxDueOnFilter: string;
    dueTodayFilter: string;
    skipCountFilter: number;
    MaxCountFilter: number;
    pageSize: number
    //selectedQueue: any;
    selectedAssignToUser: any;
    selectedStatus: any;
    sorting:string;


    constructor() {
        this.taskNameFilter = null,
            this.nameFilter = null,
            this.fileNameFilter = null,
            this.caseNumberFilter = null,
            this.caseTypeFilter = null,
            this.caseRecordNameFilter = null,
            this.queueFilter = null,
            this.assignToFilter = null,
            this.statusFilter = null,
            this.organizationUnitFilter = null,
            //  minDateRangeFilter:Date,
            //maxDateRangeFilter:Date,
            this.minDueOnFilter = null,
            this.maxDueOnFilter = null,
            this.dueTodayFilter = null,
            this.skipCountFilter = 0,
            this.MaxCountFilter = 10
            this.pageSize = 10,
            this.sorting=null;
    }
}

export enum GridFilters{
    taskNameFilter="taskNameFilter",
    nameFilter="nameFilter",
    fileNameFilter="fileNameFilter",
    caseNumberFilter="caseNumberFilter",
    caseTypeFilter="caseTypeFilter",
    caseRecordNameFilter="caseRecordNameFilter",
    queueFilter="queueFilter",
    assignToFilter="assignToFilter",
    statusFilter="statusFilter",
    organizationUnitFilter="organizationUnitFilter",
    minDueOnFilter="minDueOnFilter",
    maxDueOnFilter="maxDueOnFilter",
    dueTodayFilter="dueTodayFilter",
    skipCountFilter="skipCountFilter",
    MaxCountFilter="MaxCountFilter",
    pageSize="pageSize",
    selectedAssignToUser="selectedAssignToUser",
    sorting="sorting"

}