import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { MomentInput } from 'moment';

@Pipe({ name: 'momentFormat' })
export class MomentFormatPipe implements PipeTransform {
    transform(value: MomentInput, format: string, isLocale: boolean = false) {
        if (!value) {
            return '';
        }
        if (isLocale) {
            let date = new Date(value.toString());
            let localDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
            return moment(localDate).format(format);
        }
        else {
            return moment(value).format(format);
        }

    }
}
