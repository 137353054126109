export class DashboardCustomizationConst {
    static widgets = {
        tenant: {
            // profitShare: "Widgets_Tenant_ProfitShare",
            // memberActivity: "Widgets_Tenant_MemberActivity",
            // regionalStats: "Widgets_Tenant_RegionalStats",
            // salesSummary: "Widgets_Tenant_SalesSummary",
            // topStats: "Widgets_Tenant_TopStats",
            // generalStats: "Widgets_Tenant_GeneralStats",
            // dailySales: "Widgets_Tenant_DailySales",
            // //  adding host widgets to teanent as well
            // taskCountByStatus: "Widgets_Tenant_TaskCountByStatus",
            // ageOfPendingTasks: "Widgets_Tenant_AgeOfPendingTasks",
            // ageOfCompletedTasks: "Widgets_Tenant_AgeOfCompletedTasks",
            // // widgetsubscriptionsStatus: 'Widgets_Tenant_widgetsubscriptionsStatus',
            // // widgetOrganizationsStatus: 'Widgets_Tenant_WidgetOrganizationsStatus',
            // completedTasksByUser: "Widgets_Tenant_CompletedTasksByUser",
            // tasksVerifiedByUserByHour: "Widget_Teanent_TasksVerifiedByUserByHour",
        },
        host: {
            // topStats: "Widgets_Host_TopStats",
            // incomeStatistics: "Widgets_Host_IncomeStatistics",
            // editionStatistics: "Widgets_Host_EditionStatistics",
            // subscriptionExpiringTenants: "Widgets_Host_SubscriptionExpiringTenants",
            // recentTenants: "Widgets_Host_RecentTenants",
            // taskCountByStatus: "Widgets_Host_TaskCountByStatus",
            // ageOfPendingTasks: "Widgets_Host_AgeOfPendingTasks",
            // ageOfCompletedTasks: "Widgets_Host_AgeOfCompletedTasks",
            // // widgetsubscriptionsStatus: 'Widgets_Host_widgetsubscriptionsStatus',
            // // widgetOrganizationsStatus: 'Widgets_Host_WidgetOrganizationsStatus',
            // completedTasksByUser: "Widgets_Host_CompletedTasksByUser",
            // tasksVerifiedByUserByHour: "Widgets_Host_TaskVerifiedByHourByHour",
        },
    };
    static filters = {
        filterDateRangePicker: "Filters_DateRangePicker",
    };
    static dashboardNames = {
        defaultTenantDashboard: "TenantDashboard",
        defaultHostDashboard: "HostDashboard",
    };
    static Applications = {
        Angular: "Angular",
    };
}
