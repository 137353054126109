import { Injectable, OnInit } from "@angular/core";
import {
    WidgetViewDefinition,
    WidgetFilterViewDefinition,
} from "./definitions";
// import { DashboardCustomizationConst } from "./DashboardCustomizationConsts";
// import { WidgetGeneralStatsComponent } from "./widgets/widget-general-stats/widget-general-stats.component";
// import { WidgetDailySalesComponent } from "./widgets/widget-daily-sales/widget-daily-sales.component";
// import { WidgetProfitShareComponent } from "./widgets/widget-profit-share/widget-profit-share.component";
// import { WidgetMemberActivityComponent } from "./widgets/widget-member-activity/widget-member-activity.component";
// import { WidgetRegionalStatsComponent } from "./widgets/widget-regional-stats/widget-regional-stats.component";
// import { WidgetSalesSummaryComponent } from "./widgets/widget-sales-summary/widget-sales-summary.component";
// import { FilterDateRangePickerComponent } from "./filters/filter-date-range-picker/filter-date-range-picker.component";
// import { WidgetTopStatsComponent } from "./widgets/widget-top-stats/widget-top-stats.component";
// import { WidgetAgeOfCompletedTasksComponent } from "./widgets/widget-age-of-completed-tasks/widget-age-of-completed-tasks.component";
// import { WidgetTaskCountByStatusComponent } from "./widgets/widget-task-count-by-status/widget-task-count-by-status.component";
// import { WidgetAgeOfPendingTasksComponent } from "./widgets/widget-age-of-pending-tasks/widget-age-of-pending-tasks.component";
// import { WidgetPendingTasksByUserComponent } from "./widgets/widget-pending-tasks-by-user/widget-pending-tasks-by-user.component";
// import { WidgetCompletedTasksByUserComponent } from "./widgets/widget-completed-tasks-by-user/widget-completed-tasks-by-user.component";
// import { WidgetTasksVerifiedByUserByHourComponent } from "./widgets/widget-tasks-verified-by-user-by-hour/widget-tasks-verified-by-user-by-hour.component";
// import { WidgetSubscriptionsStatusComponent } from "./widgets/WidgetSubscriptionsStatus/WidgetSubscriptionsStatus";
// import { OrganizationStatusComponent } from './widgets/Organization-Status/Organization-Status.component';


@Injectable({
    providedIn: "root",
})
export class DashboardViewConfigurationService {
    public WidgetViewDefinitions: WidgetViewDefinition[] = [];
    public widgetFilterDefinitions: WidgetFilterViewDefinition[] = [];

    constructor() {
        this.initializeConfiguration();
    }

    private initializeConfiguration() {
        // let filterDateRangePicker = new WidgetFilterViewDefinition(
        //     DashboardCustomizationConst.filters.filterDateRangePicker,
        //     FilterDateRangePickerComponent
        // );
        // //add your filters here
        // this.widgetFilterDefinitions.push(filterDateRangePicker);

        // let generalStats = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.tenant.generalStats,
        //     WidgetGeneralStatsComponent,
        //     6,
        //     4
        // );

        // let dailySales = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.tenant.dailySales,
        //     WidgetDailySalesComponent
        // );

        // let profitShare = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.tenant.profitShare,
        //     WidgetProfitShareComponent
        // );

        // let memberActivity = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.tenant.memberActivity,
        //     WidgetMemberActivityComponent
        // );

        // let regionalStats = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.tenant.regionalStats,
        //     WidgetRegionalStatsComponent
        // );

        // let salesSummary = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.tenant.salesSummary,
        //     WidgetSalesSummaryComponent
        // );

        // let topStats = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.tenant.topStats,
        //     WidgetTopStatsComponent
        // );
        // //add your tenant side widgets here

        // let incomeStatistics = new WidgetViewDefinition(
        //   DashboardCustomizationConst.widgets.host.incomeStatistics,
        //   WidgetIncomeStatisticsComponent,
        // );

        // let editionStatistics = new WidgetViewDefinition(
        //   DashboardCustomizationConst.widgets.host.editionStatistics,
        //   WidgetEditionStatisticsComponent,
        // );

        // let recentTenants = new WidgetViewDefinition(
        //   DashboardCustomizationConst.widgets.host.recentTenants,
        //   WidgetRecentTenantsComponent,
        // );

        // let subscriptionExpiringTenants = new WidgetViewDefinition(
        //   DashboardCustomizationConst.widgets.host.subscriptionExpiringTenants,
        //   WidgetSubscriptionExpiringTenantsComponent
        // );

        // let hostTopStats = new WidgetViewDefinition(
        //   DashboardCustomizationConst.widgets.host.topStats,
        //   WidgetHostTopStatsComponent,
        // );

        // let taskCount = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.host.taskCountByStatus,
        //     WidgetTaskCountByStatusComponent,
        //     6,
        //     15
        // );

        // let ageOfPendingTasks = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.host.ageOfPendingTasks,
        //     WidgetAgeOfPendingTasksComponent,
        //     6,
        //     15
        //     //WidgetAgeOfPendingTasksComponent
        // );
        // let tasksVerifiedByUserByHour = new WidgetViewDefinition(
        //   DashboardCustomizationConst.widgets.host.tasksVerifiedByUserByHour,
        //   WidgetTaskVerfiedByUserByHourComponent, 6, 15
        // );

        // let ageOfCompletedTasks = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.host.ageOfCompletedTasks,
        //     WidgetAgeOfCompletedTasksComponent,
        //     6,
        //     15
        // );

        // let WidgetsubscriptionsStatus = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.host.widgetsubscriptionsStatus,
        //     WidgetSubscriptionsStatusComponent,
        //     6,
        //     15
        // );
        // let WidgetorganizationsStatus = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.host.widgetOrganizationsStatus,
        //     OrganizationStatusComponent,
        //     6,
        //     15
        // );

        // let completedTasksByUser = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.host.completedTasksByUser,
        //     WidgetCompletedTasksByUserComponent,
        //     6,
        //     15
        // );
        // let HostTasksVerifiedByUserByHour = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.tenant.tasksVerifiedByUserByHour,
        //     WidgetTasksVerifiedByUserByHourComponent,
        //     6,
        //     15
        // );

        //add your host side widgets here

        // this.WidgetViewDefinitions.push(generalStats);
        // this.WidgetViewDefinitions.push(dailySales);
        // this.WidgetViewDefinitions.push(profitShare);
        // this.WidgetViewDefinitions.push(memberActivity);
        // this.WidgetViewDefinitions.push(regionalStats);
        // this.WidgetViewDefinitions.push(salesSummary);
        // this.WidgetViewDefinitions.push(topStats);
        // this.WidgetViewDefinitions.push(taskCount);
        // this.WidgetViewDefinitions.push(ageOfPendingTasks);
        // this.WidgetViewDefinitions.push(ageOfCompletedTasks);
        // // this.WidgetViewDefinitions.push(WidgetsubscriptionsStatus);
        // // this.WidgetViewDefinitions.push(WidgetorganizationsStatus);
        // this.WidgetViewDefinitions.push(completedTasksByUser);
        // this.WidgetViewDefinitions.push(HostTasksVerifiedByUserByHour);

        // // this.WidgetViewDefinitions.push(tasksVerifiedByUserByHour);

        // //#region  adding host widgets to teanent

        // let TenanttaskCount = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.tenant.taskCountByStatus,
        //     WidgetTaskCountByStatusComponent,
        //     6,
        //     15
        // );

        // let TenantageOfPendingTasks = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.tenant.ageOfPendingTasks,
        //     WidgetAgeOfPendingTasksComponent,
        //     6,
        //     15
        //     //WidgetAgeOfPendingTasksComponent
        // );

        // let TenantageOfCompletedTasks = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.tenant.ageOfCompletedTasks,
        //     WidgetAgeOfCompletedTasksComponent,
        //     6,
        //     15
        // );

        // // let TenantwidgetsubscriptionsStatus = new WidgetViewDefinition(
        // //     DashboardCustomizationConst.widgets.tenant.widgetsubscriptionsStatus,
        // //     WidgetSubscriptionsStatusComponent, 6, 15
        // // );

        // let TenantwidgetorganizationsStatus = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.tenant.widgetOrganizationsStatus,
        //     OrganizationStatusComponent,
        //     6,
        //     15
        // );

        // let TenantcompletedTasksByUser = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.tenant.completedTasksByUser,
        //     WidgetCompletedTasksByUserComponent,
        //     6,
        //     15
        // );

        // let TenantTasksVerifiedByUserByHour = new WidgetViewDefinition(
        //     DashboardCustomizationConst.widgets.tenant.tasksVerifiedByUserByHour,
        //     WidgetTasksVerifiedByUserByHourComponent,
        //     6,
        //     15
        // );

        // // WidgetTasksVerifiedByUserByHourComponent

        // this.WidgetViewDefinitions.push(TenanttaskCount);
        // this.WidgetViewDefinitions.push(TenantageOfPendingTasks);
        // this.WidgetViewDefinitions.push(TenantageOfCompletedTasks);
        // // this.WidgetViewDefinitions.push(TenantwidgetsubscriptionsStatus);
        // // this.WidgetViewDefinitions.push(TenantwidgetorganizationsStatus);
        // this.WidgetViewDefinitions.push(TenantcompletedTasksByUser);
        // this.WidgetViewDefinitions.push(TenantTasksVerifiedByUserByHour);
        // //#endregion
    }
}
