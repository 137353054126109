import { SharedService } from './../../../../shared.service';
import { Component, OnInit, Injector } from '@angular/core';
import { TxWidgetServiceService } from '../../TextExtractor-Widget-Services/Tx-widget-service.service';
import { WidgetComponentBase } from "../WidgetComponentBase";
import { Subject } from 'rxjs';
import { MessagingService } from '@app/@core/messaging';
import { DashboardCustomizationConst } from '../../DashboardCustomizationConsts';

@Component({
  selector: 'tX-widget-age-of-pending-tasks',
  templateUrl: './widget-age-of-pending-tasks.component.html',
  styleUrls: ['./widget-age-of-pending-tasks.component.css']
})
export class WidgetAgeOfPendingTasksComponent extends WidgetComponentBase implements OnInit {
  QueuesList: Subject<any> = new Subject();
  selectedQueue: any;
  single: any[

  ];
  view: any[] = [700, 300];  //width,height

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = false;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  below: 'below'
  yAxisLabel: string = 'Days';
  showYAxisLabel: boolean = true;
  xAxisLabel: string = 'Number of Tasks';

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };
  onSelect(data): void {
  }

  onActivate(data): void {
  }

  onDeactivate(data): void {
  }
  constructor(injector: Injector,
    private widgetservice: TxWidgetServiceService,
    private messageservice: MessagingService
  ) {
    super(injector);
    this.single = [];
  }

  ngOnInit() {
    this.getAllRequiredLookUps();
    this.messageservice.getMessage().subscribe(msg => {

      // if (msg.text == DashboardCustomizationConst.widgets.tenant.ageOfPendingTasks) {
      //   this.view = [msg.val.width, msg.val.height];
      // }
    });
  }
  getAllRequiredLookUps() {
    this.widgetservice.GetAllLookUpsForHumanTaskPage().subscribe(
      res => {
        this.QueuesList.next(res.result.items);
        // this.selectedQueue = {
        //   taskQueue: {
        //     id: res.result.items[0].taskQueue.id,
        //     name: res.result.items[0].taskQueue.name
        //   }
        // }
        this.GetAgeOfPendingTasks('');


      }
    )
  }


  selectedqueue(selqueuename: any) {
    this.GetAgeOfPendingTasks(selqueuename.id);
  }

  GetAgeOfPendingTasks(id) {
    if (id != '') {
      id = "?taskQueueIds=" + id;
    }
    this.widgetservice.GetAgeOfPendingTasks(id).subscribe(
      res => {
        this.single = res.result;
      }
    );


  }
}
