// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
    production: false,
    hmr: false,
    appConfig: "appconfig.json",
    RefillUrl: "https://openbots.ai/Refill-Documents",
    InActiveTenantRedirct: "https://openbots.ai/product/openbots-documents/",
    AUTH_ISSUER: "https://cloud-dev.openbots.ai",
    User_Profile_URI: "https://cloud-dev.openbots.ai/members/userprofile",
    Change_Password_URI: "https://cloud-dev.openbots.ai/members/changepassword",
    AUTH_REDIRECT_URI: 'http://documents.openbots-dev.io',
    AUTH_POST_LOGOUT_URI: 'http://documents.openbots-dev.io',
    // AUTH_REDIRECT_URI: "http://localhost:4200",
    // AUTH_POST_LOGOUT_URI: "http://localhost:4200",
};
