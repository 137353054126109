import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class MessagingService {
    public subject = new Subject<any>();
    // public subjectArray = new Subject<any[]>();
    sendMessage(message: string, value?: any) {
        this.subject.next({ text: message, val: value });
    }

    clearMessage() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
    // sendMessageArray(message: string, value?:any[]) {
    //     this.subjectArray.next({ text: message, val: value });
    // }
}
