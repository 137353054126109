import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { CanComponentDeactivate } from '@shared/interfaces/CanComponentDeactivate';
import { TasksService } from '../services/tasks.service';

@Injectable({
    providedIn: 'root'
})
export class TasksDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
    constructor(private _taskServise: TasksService) { }
    canDeactivate() {
        this._taskServise.resetGridFilters();
        localStorage.removeItem("GridFilter");
        return true;
    }
}
