import { Injectable, Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { BaseService } from '@shared/common/base.service';
import { FileDto } from '@shared/service-proxies/service-proxies';
import { saveAs } from 'file-saver';

@Injectable()
export class FileDownloadService extends BaseService {
    constructor(injector: Injector) {
        super(injector)
    }

    downloadTempFile(file: FileDto) {
        const url = AppConsts.remoteServiceBaseUrl + '/File/DownloadTempFile?fileType=' + file.fileType + '&fileToken=' + file.fileToken + '&fileName=' + file.fileName;
        //location.href = url; //TODO: This causes reloading of same page in Firefox
        //window.open(url);

        this.http.post(url,null, {
            responseType: 'arraybuffer',
            observe: 'response',
          }).subscribe((result:any)=>{
            console.log(result);
            const blob = new Blob([result.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob,file.fileName);
            // const url = window.URL.createObjectURL(blob);
            // const winObj = window.open(url);
        })
    }
}
