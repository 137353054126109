import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessagingService } from '@app/@core/messaging';
import * as moment from 'moment';
import { LazyLoadEvent } from 'primeng';
import { TxWidgetServiceService } from '../../TextExtractor-Widget-Services/Tx-widget-service.service';
import { WidgetComponentBase } from "../WidgetComponentBase";

@Component({
  selector: 'app-widget-tasks-verified-by-user-by-hour',
  templateUrl: './widget-tasks-verified-by-user-by-hour.component.html',
  styleUrls: ['./widget-tasks-verified-by-user-by-hour.component.css']
})
export class WidgetTasksVerifiedByUserByHourComponent extends WidgetComponentBase implements OnInit {
  displayedColumns: string[] = ['name', 'value'];
  dataSource: MatTableDataSource<any>;
  selectedItems: Array<TaskVerfication>;
  requiredDate: Date;
  taskVerificationList: TaskVerficationByHour[];
  taskVerificationHeaders: TaskVerficationByHour[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private dialogRef = null;

  constructor(private injector: Injector,

    private widgetservice: TxWidgetServiceService,
    private messageservice: MessagingService
  ) {
    super(injector);
    this.dialogRef = this.injector.get(MatDialogRef, null);
    this.selectedItems = new Array<TaskVerfication>();

  }
  customers: any[];
  isDataAvailable = false;
  requiredDateString: string = null;

  headerCols: any;
  myStyle(): object {
    return {
      "border-style-": "solid",
      "border-color-": "red",
    };
  }
  ngOnInit() {

  }

  GetTaskVerifiedByUser(event?: LazyLoadEvent) {


    if (this.requiredDateString == null) {
      let date = new Date().toISOString();
      this.requiredDateString = date;
    }

    this.widgetservice.GetAllTaskByDate(this.requiredDateString)
      .subscribe(
        res => {
          this.selectedItems = new Array<TaskVerfication>();
          this.primengTableHelper.records = null;
          this.taskVerificationList = res.result;
          this.LocalizeDate();
          this.ResetHeaders();
          this.GroupByName();

          for (var value in this.taskVerificationList) {
            let a = this.taskVerificationList[value];

            var obj = new TaskVerfication();
            obj.AssignedTo = value;

            let res = this.GroupByHours(a);

            for (var item in res) {
              obj.TaskVerficationByHour.push(res[item]);
            }

            let length = 0;

            for (var item in a) {
              length++;
            }

            obj.TaskDate = a.lastModificationTime;
            obj.hoursToDosplay = this.SetHoursToDisplay(a);
            obj.Task = length;
            obj.AssignedTo = a[0].assignedTo;

            this.selectedItems.push(obj);
          }

          this.primengTableHelper.records = this.selectedItems;
          this.primengTableHelper.totalRecordsCount = this.selectedItems.length;
        }
      );
  }

  LocalizeDate() {
    for (let item of this.taskVerificationList) {
      item.lastModificationTime = this.formatDate(item.lastModificationTime);
      item.hour = this.getHoursFromDate(item.lastModificationTime);
    }
  }
  ResetHeaders() {
    this.headerCols = [
      { field: "00:00", header: "" }
    ]
    this.taskVerificationHeaders = this.GroupByHours(this.taskVerificationList);
    for (var value in this.taskVerificationHeaders) {
      let a = this.taskVerificationHeaders[value];
      var obj = new TaskVerfication();
      let length = 0;

      for (var item in a) {
        length++;
      }

      obj.TaskDate = a.lastModificationTime;
      obj.hoursToDosplay = this.SetHoursToDisplay(a);
      obj.Task = length;
      obj.AssignedTo = a[0].assignedTo;

      this.headerCols.push({ field: obj.hoursToDosplay, header: obj.hoursToDosplay });
    }


  }
  SetHoursToDisplay(a: any) {
    if (a[0].hour < 10) {
      return "0" + a[0].hour + ":00";
    }
    else {
      return a[0].hour + ":00";
    }
  }
  GroupByHours(list: any) {
    return list.reduce(function (r, a) {
      r[a.hour] = r[a.hour] || [];
      r[a.hour].push(a);
      return r;
    }, Object.create(null));
  }
  GroupByName() {
    this.taskVerificationList = this.taskVerificationList.reduce(function (r, a) {
      r[a.assignedTo] = r[a.assignedTo] || [];
      r[a.assignedTo].push(a);
      return r;
    }, Object.create(null));
  }
  DateChanged() {
    if (this.requiredDate != undefined) {
      this.requiredDateString = this.requiredDate.toISOString();
    }
    this.GetTaskVerifiedByUser();
  }

  formatDate(date) {
    if (date == null) {
      return date
    }
    let localTime = moment
      .utc(date)
      .local()
      .format("l LT");
    return localTime;
  }

  getHoursFromDate(date) {
    if (date == null) {
      return date
    }
    let localTime = moment
      .utc(date)
      .hours();

    if (localTime > 12)
      localTime -= 12;
    return localTime;
  }
}
export class TaskVerfication {
  TaskDate: Date;
  Task: number = 0;
  AssignedTo: string = null;
  TaskVerficationByHour = new Array<TaskVerficationByHour>();
  hoursToDosplay: string = "00:00";

}
class TaskVerficationByHour {
  name: string;
  caseNumber: string;
  caseType: string;
  dueOn: Date;
  status: string;
  assignedTo: string;
  taskQueueId: string;
  documentSessionID: string;
  organizationUnitId: number;
  userId: number;
  createdOn: Date;
  createdBy: number;
  lastModificationTime: Date;
  isSelected: boolean;
  taskQueueName: string;
  organizationUnitDisplayName: string;
  userName: string;
  id: string;

  hour?: number;

}

























//extends WidgetComponentBase implements OnInit {
//   displayedColumns: string[] = ['name', 'value'];
//   dataSource: MatTableDataSource<any>;

//   @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
//   @ViewChild(MatSort, { static: true }) sort: MatSort;
//   constructor(injector: Injector,
//     private widgetservice: TxWidgetServiceService,
//      private messageservice:MessagingService
//   ) {
//     super(injector);
//   }

//   ngOnInit() {


//     this.GetTaskVerifiedByUser();

//   }

//   GetTaskVerifiedByUser()
//   {
//     this.widgetservice.GetPendingTaskByUser().subscribe(
//       res => {
//         this.dataSource = new MatTableDataSource(res.result);
//         this.dataSource.paginator = this.paginator;
//         this.dataSource.sort = this.sort;
//       }
//     );

//   }

// }
