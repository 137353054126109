// import { SharedService } from './../../shared/shared.service';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { SharedService } from '@app/shared/shared.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

    toaster: boolean = true;
    requestCounter = 0;
    constructor(private router: Router, public sharedService: SharedService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requestCounter++;
        // this check was for sorter lazy load image and issue number 8259
        if (
            !(req.headers.has('noloader') ||
                req.url.includes("/DocumentProcessingEngineService/GetValidationName") ||
                req.url.includes("/DocumentProcessingEngineService/GetDocumentData") ||
                req.url.includes("/DocumentProcessingEngineService/GetAllForms?") ||
                req.url.includes("/DocumentProcessingEngineService/GetPageImage")
            )
        ) {
            this.sharedService.show();
        }


        return next.handle(req).pipe(

            finalize(
                () => {
                    this.requestCounter--;
                    if (this.requestCounter == 0) {

                        this.sharedService.hide();
                    }

                }));
    }
}
