import { IDocumentSorter, IFormLable, IJobRequestStats, ILookupitems, ITaskEmail, ITaskItem, ITaskQueueItems, IUser, SortedDocument, TaskGridFilters } from '@app/tasks/interfaces/ITASK';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { forkJoin, Observable, Subject, Subscriber, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IDocumentDetail, IDocumentRequest, IForm, ILookupViewModel, IProcessedDocument, ITask } from '../interfaces/ITASK';
import * as moment from 'moment';
import { BaseService } from '@shared/common/base.service';
import { FileParameter, IGetHumanTaskForViewDto, IHumanTask, IListResultDtoOfOrganizationUnitDto, SubmitDocumentResponse } from '@shared/service-proxies/service-proxies';
import { Table } from 'primeng';
@Injectable({
    providedIn: "root",
})
export class TasksService extends BaseService {
    //#region variables
    taskDetails = undefined as IGetHumanTaskForViewDto;   // SHARED TASK DETAIL
    //allDocumentsList: IDocuments[] = [];   //  for logic "Documents has Erros in document detail component "
    SelectedDoc = new Subject<IProcessedDocument>();
    private _lookups: [] = [];
    ToggleSensitivity = new Subject<any>();
    UnStructuredText = new Subject();
    formLableList = new Array<IFormLable>(); // List of labels and its cordinates of form
    //#endregion
    baseUrl: string;
    //humantask grid filters
    taskGridFilters = new TaskGridFilters();

    constructor(injector: Injector) {
        super(injector);
    }
    resetGridFilters() {
        this.taskGridFilters = new TaskGridFilters();
    }
    getPendingTaskCount() {
        //#region  Note
        // if task is assigned to login user  then we have to send  2 params
        // 1 - assignedTo
        // 2 - taskQueueNameFilter

        //#endregion
        if (
            this.taskDetails.humanTask.assignedTo.toLowerCase() != "unassigned"
        ) {
            var params = new HttpParams()
                .append("taskQueueNameFilter", this.taskDetails.taskQueueName)
                .append("assignedTo", this.taskDetails.humanTask.assignedTo);
        } else {
            var params = new HttpParams().append(
                "taskQueueNameFilter",
                this.taskDetails.taskQueueName
            );
        }
        return this.http.get<number>(
            this.rootUrl + "/api/services/app/HumanTasks/GetPendingTaskCount",
            { headers: { noloader: "true" }, params: params }
        );
    }

    changeStatus(taskID: string, status: string): Observable<any> {
        let params = new HttpParams();
        params = params.append("humanTaskId", taskID);
        params = params.append("newStatus", status);
        return this.http
            .post<any>(
                this.rootUrl +
                "/api/services/app/DocumentProcessingEngineService/ChangeStatus?humanTaskId=" +
                taskID +
                "&newStatus=" +
                status,
                {}
            )
            .pipe(
                map((res) => {
                    return this.returnResult(res);
                })
            );
    }
    downloadTaskFile(id) {
        return this.http.get<Blob>(
            AppConsts.remoteServiceBaseUrl +
            "/api/services/app/HumanTasks/GetDownloadFile?id=" +
            id,
            { observe: "body", responseType: "blob" as "json" }
        );
    }
    createOrEditTask(task: IHumanTask) {
        return this.http
            .post<any>(
                this.rootUrl + "/api/services/app/HumanTasks/CreateOrEdit",
                task
            )
            .pipe(
                map((res) => {
                    return this.returnResult(res);
                })
            );
    }
    getAllLookUpsForHumanTaskPage(): Observable<any[]> {
        var queuelist = this.http
            .get<any>(
                this.rootUrl +
                "/api/services/app/TaskQueues/GetAll?MaxResultCount=1000",
                { headers: new HttpHeaders({ Accept: "text/plain" }) }
            )
            .pipe(
                map((res) => {
                    return this.returnResult(res);
                })
            );

        var assigntolist = this.http
            .get<any[]>(
                this.rootUrl +
                "/api/services/app/HumanTasks/GetAllUserForTableDropdown",
                { headers: new HttpHeaders({ Accept: "text/plain" }) }
            )
            .pipe(
                map((res) => {
                    return this.returnResult(res);
                })
            );

        var statuslist = this.http
            .get<any>(
                this.rootUrl +
                "/api/services/app/Lookups/GetAll?Filter=HumanTaskStatus",
                { headers: new HttpHeaders({ Accept: "text/plain" }) }
            )
            .pipe(
                map((res) => {
                    return this.returnResult(res);
                })
            );


        var teams = this.http
            .get<any>(
                this.rootUrl +
                "/api/services/app/OrganizationUnit/GetOrganizationUnits?MaxResultCount=100",
                { headers: new HttpHeaders({ Accept: "text/plain" }) }
            )
            .pipe(
                map((res) => {
                    return this.returnResult(res);
                })
            );

        return forkJoin([queuelist, assigntolist, statuslist, teams]);
    }

    All_API_0f_ViewTaskPage(taskID) {
        var progress = this.http
            .get<any>(
                this.rootUrl +
                "/api/services/app/DocumentProcessingEngineService/GetValidationProgress?humanTaskId=" +
                taskID
            )
            .pipe(
                catchError((error) => {
                    return throwError(error);
                })
            );
        var statuses = this.http
            .get<any>(
                this.rootUrl +
                "/api/services/app/DocumentProcessingEngineService/PossibleNextStates?humanTaskId=" +
                taskID
            )
            .pipe(
                catchError((error) => {
                    return throwError(error);
                })
            );
        var attachments = this.http
            .get<any>(
                this.rootUrl +
                "/api/services/app/HumanTasks/GetAttachments?humanTaskId=" +
                taskID
            )
            .pipe(
                catchError((error) => {
                    return throwError(error);
                })
            );
        return forkJoin([progress, statuses, attachments]);
    }
    extractTextFrom_Box(humanTaskId, documentId, pageNumber, x1, y1, x2, y2) {
        return this.http
            .get<any>(
                `${this.rootUrl}/api/services/app/DocumentProcessingEngineService/GetPageTextInABox?humanTaskId=${humanTaskId}&documentId=${documentId}&pageNumber=${pageNumber}&x1=${x1}&y1=${y1}&x2=${x2}&y2=${y2}`
            )
            .pipe(
                map((res) => {
                    return this.returnResult(res);
                })
            );
    }
    // getAllForm(): Observable<IForm[]> {
    //     var header = {
    //         headers: new HttpHeaders().set(
    //             "Authorization",
    //             `Bearer ${abp.auth.getToken()}`
    //         ),
    //     };
    //     return this.http
    //         .get(
    //             `${this.rootUrl}/api/services/app/DocumentProcessingEngineService/GetAllForms`,
    //             header
    //         )
    //         .pipe(
    //             map((res) => {
    //                 return this.returnResult(res);
    //             })
    //         );
    // }

    // getAllFormsByEntityId(entityId: string): Observable<any> {
    //     return this.http
    //         .get<any>(
    //             `${this.rootUrl}/api/services/app/DocumentProcessingEngineService/GetAllForms?entityId=${entityId}`
    //         )
    //         .pipe(
    //             map((res) => {
    //                 return this.returnResult(res);
    //             })
    //         );
    // }
    getFieldValidation(validationIds: string[]) {
        return this.http
            .post(
                `${this.rootUrl}/api/services/app/DocumentProcessingEngineService/GetValidationName`,
                validationIds
            )
            .pipe(
                map((res) => {
                    return this.returnResult(res);
                })
            );
    }
    findNextTask(humanTaskId, documentId, taskQueueNameFilter) {
        var bodyobj = {
            humanTaskId: humanTaskId,
            documentId: documentId,
            taskQueueNameFilter: taskQueueNameFilter,
        };
        return this.http
            .post<any>(
                this.rootUrl + "/api/services/app/HumanTasks/FindNextTask",
                bodyobj
            )
            .pipe(
                map((res) => {
                    return this.returnResult(res);
                })
            );
    }
    getFullImage(imagePath) {
        let url = this.rootUrl + "/" + imagePath;
        return this.http.get(url, { headers: { noloader: "true" } }).pipe(
            map((blob: any) => {
                let objectURL = "data:image/jpeg;base64," + blob.result;
                blob = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                return blob;
            })
        );
    }
    createSorterTasks(sortedtasks: SortedDocument) {
        return this.http.post<any>(
            this.rootUrl + "/api/services/app/HumanTasks/CreateSorterTasks",
            sortedtasks
        );
    }
    getFormLayout() {
        return this.http.get<any>(
            this.rootUrl + "/api/services/app/CaseRecords/GetFormLayout"
        );
    }
    getDocumentsData(taskID, documentID) {
        return this.http
            .get<IDocumentDetail>(
                `${this.rootUrl}/api/services/app/DocumentProcessingEngineService/GetDocumentData?humanTaskId=${taskID}&documentId=${documentID}`
            )
            .pipe(
                map((res) => {
                    return this.returnResult(res) as IDocumentDetail;
                })
            );
    }
    getCases(querystring: string) {
        let url = this.rootUrl + "/api/services/app/CaseRecords/GetAll";
        if (querystring != "") {
            url = url + "?" + querystring;
        }
        return this.http.get<any>(url).pipe(
            map((res) => {
                return this.returnResult(res);
            })
        );
    }
    getAllInternalCases(querystring: string) {
        let url = this.rootUrl + "/api/services/app/CaseRecords/GetAllInternalCases";
        if (querystring != "") {
            url = url + "?" + querystring;
        }
        return this.http.get<any>(url).pipe(
            map((res) => {
                return this.returnResult(res);
            })
        );
    }
    getCaseHeaders() {
        let url = this.rootUrl + "/api/services/app/CaseRecordFieldses/GetAll";
        return this.http.get<any>(url);
    }
    getCaseRecordForEdit(Id) {
        let url =
            this.rootUrl +
            "/api/services/app/CaseRecords/GetCaseRecordForEdit?id=" +
            Id;
        return this.http.get<any>(url);
    }
    createOrEditCase(caseObj: any) {
        return this.http
            .post<any>(
                this.rootUrl + "/api/services/app/CaseRecords/CreateOrEdit",
                caseObj
            )
            .pipe(
                map((res) => {
                    return this.returnResult(res);
                })
            );
    }
    getImageofthePage(imageUrlEndPoint) {
        return this.http.get<IProcessedDocument[]>(`${this.rootUrl}/${imageUrlEndPoint}`).pipe(
            map((blob: any) => {
                let objectURL = 'data:image/jpeg;base64,' + blob.result;
                blob = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                return blob;
            }));
    }







    getDocuments(sessionId: string): Observable<IProcessedDocument[]> {
        return this.http.get<IProcessedDocument[]>(`${this.rootUrl}/processed-documents/${sessionId}`);
    }
    getTaskSplitterRecord(humantaskid): Observable<IDocumentSorter[]> {
        return this.http
            .get<IDocumentSorter[]>(
                `${this.rootUrl}/api/services/app/HumanTasks/GetTaskSplitterRecord?humanTaskId=${humantaskid}`
            )
            .pipe(
                map((res: any) => {
                    let result = new Array<IDocumentSorter>();
                    result = res.result;
                    result.forEach((res) => {
                        res.loading = true;
                        res.isDeleted = false;
                        res.newTaskNumber =
                            res.originalTaskNumber != null
                                ? res.originalTaskNumber
                                : 1;
                    });
                    return result;
                })
            );
    }
    getTaskDocuments(taskID: string): Observable<IProcessedDocument[]> {
        return this.http
            .get<IProcessedDocument[]>(
                this.rootUrl +
                "/api/services/app/DocumentProcessingEngineService/GetDocuments?humanTaskId=" +
                taskID
            )
            .pipe(
                map((res: any) => {
                    res.result.map((doc) => (doc.selected = false));
                    return this.returnResult(res);
                })
            );
    }
    getPossibleNextStatus(taskID: string) {
        return this.http.get<any>(
            this.rootUrl +
            "/api/services/app/DocumentProcessingEngineService/PossibleNextStates?humanTaskId=" +
            taskID
        );
    }
    getLookUpsForFinishForting(taskID: string) {
        var possiblestatus = this.http.get<any>(
            this.rootUrl +
            "/api/services/app/DocumentProcessingEngineService/PossibleNextStates?humanTaskId=" +
            taskID
        );

        var queuelist = this.http.get<any>(
            this.rootUrl +
            "/api/services/app/TaskQueues/GetAll?MaxResultCount=1000",
            { headers: new HttpHeaders({ Accept: "text/plain" }) }
        );

        return forkJoin([possiblestatus, queuelist]);
    }
    getTaskStatus(taskID: string) {
        return this.http.get<any>(
            this.rootUrl +
            "/api/services/app/DocumentProcessingEngineService/GetStatus?humanTaskId=" +
            taskID
        );
    }
    all_APIs_of_ViewTaskPage(taskID) {
        var humantask = this.http.get<any>(
            this.rootUrl +
            "/api/services/app/HumanTasks/GetHumanTaskForView?id=" +
            taskID
        );

        var progress = this.http.get<any>(
            this.rootUrl +
            "/api/services/app/DocumentProcessingEngineService/GetValidationProgress?humanTaskId=" +
            taskID
        );

        var statuses = this.http.get<any>(
            this.rootUrl +
            "/api/services/app/DocumentProcessingEngineService/PossibleNextStates?humanTaskId=" +
            taskID
        );

        var attachments = this.http.get<any>(
            this.rootUrl +
            "/api/services/app/HumanTasks/GetAttachments?humanTaskId=" +
            taskID
        );

        return forkJoin([humantask, progress, statuses, attachments]);
    }
    // getHumanTask(taskID: string): Observable<ITask> {
    //     return this.http
    //         .get<any>(
    //             this.rootUrl +
    //             "/api/services/app/HumanTasks/GetHumanTaskForView?id=" +
    //             taskID
    //         )
    //         .pipe(
    //             map((res) => {
    //                 return this.returnResult(res);
    //             })
    //         );
    // }
    call_Apis_afterChangeStaus(taskID) {
        var status = this.http.get<any>(
            this.rootUrl +
            "/api/services/app/DocumentProcessingEngineService/GetStatus?humanTaskId=" +
            taskID
        );

        var possiblestatus = this.http.get<any>(
            this.rootUrl +
            "/api/services/app/DocumentProcessingEngineService/PossibleNextStates?humanTaskId=" +
            taskID
        );
        return forkJoin([possiblestatus, status]);
    }
    getRandomColor() {
        var color = Math.floor(0x1000000 * Math.random()).toString(16);
        return "#" + ("000000" + color).slice(-6);
    }
    getAllLookups(lookupName: string): Observable<ILookupViewModel[]> {
        var lookup = this._lookups[lookupName];
        if (!lookup) {
            var header = {
                headers: new HttpHeaders().set(
                    "Authorization",
                    `Bearer ${abp.auth.getToken()}`
                ),
            };

            return this.http
                .get<any>(
                    `${this.rootUrl}/api/services/app/Lookups/GetAll?NameFilter=${lookupName}&MaxResultCount=200`,
                    header
                )
                .pipe(
                    map((response) => {
                        var vm: ILookupViewModel[] = [];
                        if (
                            response.result.items &&
                            response.result.items.length > 0
                        ) {
                            response.result.items.forEach((ce) => {
                                vm.push(ce.lookup);
                            });
                            this._lookups[lookupName] = vm;
                            return vm;
                        }
                    }),
                    catchError((error) => throwError(error))
                );
        } else {
            return new Observable<ILookupViewModel[]>(
                (subscriber: Subscriber<ILookupViewModel[]>) =>
                    subscriber.next(lookup)
            );
        }
    }
    GetTaskSplitterRecord(humantaskid): Observable<IDocumentSorter[]> {
        return this.http
            .get<IDocumentSorter[]>(
                `${this.rootUrl}/api/services/app/HumanTasks/GetTaskSplitterRecord?humanTaskId=${humantaskid}`
            )
            .pipe(
                map((res: any) => {
                    let result = new Array<IDocumentSorter>();
                    result = res.result;
                    result.forEach((res) => {
                        res.loading = true;
                        res.isDeleted = false;
                        res.newTaskNumber =
                            res.originalTaskNumber != null
                                ? res.originalTaskNumber
                                : 1;
                    });
                    return result;
                })
            );
    }

    saveDocumentData(
        humanTaskId: string,
        documentId: string,
        documentRequest: IDocumentRequest
    ): Observable<any> {
        var header = {
            headers: new HttpHeaders().set(
                "content-type",
                "application/json; charset=utf-8"
            ),
        };
        let postData = JSON.stringify(documentRequest);
        return this.http.post(
            `${this.rootUrl}/api/services/app/DocumentProcessingEngineService/SaveDocumentData?humanTaskId=${humanTaskId}&documentId=${documentId}`,
            postData,
            header
        );
    }
    assignToMe(assignObj): Observable<any> {
        var header = {
            headers: new HttpHeaders().set(
                "content-type",
                "application/json; charset=utf-8"
            ),
        };
        let postData = JSON.stringify(assignObj);
        return this.http
            .post(`${this.rootUrl}/api/services/app/DocumentProcessingEngineService/AssignTo`, postData, header)
            .pipe(map((res) => { return this.returnResult(res); }));
    }
    getPageImage(
        humanTaskId: string,
        documentId: string,
        pageNumber: number,
        schemaNumber: number
    ) {
        return this.http
            .get(
                this.rootUrl +
                "/api/services/app/DocumentProcessingEngineService/GetPageImage?humanTaskId=" +
                humanTaskId +
                "&" +
                "documentId=" +
                documentId +
                "&" +
                "pageNumber=" +
                pageNumber +
                "&schemaNumber=" +
                schemaNumber
            )
            .pipe(
                map((res) => {
                    return this.returnResult(res);
                })
            );
    }
    getPageText(
        humanTaskId: string,
        documentId: string,
        pageNumber: number,
        schemaNumber: number
    ) {
        return this.http
            .get(
                `${this.rootUrl}/api/services/app/DocumentProcessingEngineService/GetPageText?humanTaskId=${humanTaskId}&documentId=${documentId}&pageNumber=${pageNumber}&schemaNumber=${schemaNumber}`
            )
            .pipe(
                map((res) => {
                    return this.returnResult(res);
                })
            );
    }

    markDocumentAsVerified(
        humanTaskId: string,
        documentId: string,
        isVerified: boolean
    ): Observable<any> {
        var header = {
            headers: new HttpHeaders().set(
                "content-type",
                "application/json; charset=utf-8"
            ),
        };
        return this.http
            .post(
                `${this.rootUrl}/api/services/app/DocumentProcessingEngineService/MarkDocumentAsVerified?humanTaskId=${humanTaskId}&documentId=${documentId}&isVerified=${isVerified}`,
                null,
                header
            )
            .pipe(
                map((res) => {
                    return this.returnResult(res);
                })
            );
    }
    markDocumentAsSkipped(
        humanTaskId: string,
        documentId: string,
        isSkipped: boolean
    ): Observable<any> {
        var header = {
            headers: new HttpHeaders().set(
                "content-type",
                "application/json; charset=utf-8"
            ),
        };
        return this.http
            .post(
                `${this.rootUrl}/api/services/app/DocumentProcessingEngineService/MarkDocumentAsSkipped?humanTaskId=${humanTaskId}&documentId=${documentId}&isSkipped=${isSkipped}`,
                null,
                header
            )
            .pipe(
                map((res) => {
                    return this.returnResult(res);
                })
            );
    }
    GetLookUpsForFinishForting(taskID: string) {
        var possiblestatus = this.http
            .get<any>(
                this.rootUrl +
                "/api/services/app/DocumentProcessingEngineService/PossibleNextStates?humanTaskId=" +
                taskID
            )
            .pipe(
                catchError((error) => {
                    return throwError(error);
                })
            );
        var queuelist = this.http
            .get<any>(
                this.rootUrl +
                "/api/services/app/TaskQueues/GetAll?MaxResultCount=1000",
                { headers: new HttpHeaders({ Accept: "text/plain" }) }
            )
            .pipe(
                catchError((error) => {
                    return throwError(error);
                })
            );
        return forkJoin([possiblestatus, queuelist]);
    }
    GetFormLayout() {
        return this.http
            .get<any>(
                this.rootUrl + "/api/services/app/CaseRecords/GetFormLayout"
            )
            .pipe(
                map((res) => {
                    return this.returnResult(res);
                })
            );
    }
    GetCaseRecordForEdit(Id) {
        let url =
            this.rootUrl +
            "/api/services/app/CaseRecords/GetCaseRecordForEdit?id=" +
            Id;
        return this.http.get<any>(url).pipe(
            map((res) => {
                return this.returnResult(res);
            })
        );
    }
    // CreateSorterTasks(sortedtasks: SortedDocument) {
    //     return this.http.post<any>(
    //         this.rootUrl + "/api/services/app/HumanTasks/CreateSorterTasks",
    //         sortedtasks
    //     );
    // }
    getJobRequestStats(sessionId: string): Observable<IJobRequestStats> {
        return this.http.get<IJobRequestStats>(`${this.rootUrl}/import-statistics/${sessionId}`)
            .pipe(map((res) => { return this.returnResult(res); }));
    }

    verifyDocument(jobRequest: any): Observable<any> {
        let body = JSON.stringify(jobRequest);
        return this.http.post(`${this.rootUrl}/verify-document`, body);
    }
    GetStatistics(TaskID: string) {
        return this.http
            .get<any>(
                this.rootUrl +
                "/api/services/app/DocumentProcessingEngineService/GetStatistics?humanTaskId=" +
                TaskID
            )
            .pipe(
                catchError((error) => {
                    return throwError(error);
                })
            )
            .pipe(
                map((res) => {
                    return this.returnResult(res);
                })
            );
    }
    is_Task_AssignedTo_LoggedinUser() {
        return (
            this.getActiveUserName().toLowerCase() ==
            this.taskDetails?.humanTask?.assignedTo.toLowerCase()
        );
    }
    // getActiveUserName() {
    //     return this.appSession.user.name + (this.appSession.user.surname != "" ? " " + this.appSession.user.surname : "");
    // }

    findLable(labels: IFormLable[], key, parent_key, index, returnLbl: IFormLable) {
        let keepGoing = true;
        labels.forEach(lbl => {
            if (keepGoing) {
                if (lbl.Key.toLowerCase() == key.toLowerCase() && lbl.index == index && lbl.ParentGroup == parent_key) {
                    keepGoing = false;
                    returnLbl = JSON.parse(JSON.stringify(lbl));
                }
                if (lbl.labels != undefined) {
                    returnLbl = this.findLable(lbl.labels, key, parent_key, index, returnLbl);
                }
            }
        });
        return returnLbl;
    }
    findLabelArray(labels: IFormLable[], parent_key: string, returnlist?: IFormLable[]) {
        let keepGoing = true;
        labels.forEach((lbl) => {
            if (keepGoing) {
                if (lbl.Key.toLowerCase() == parent_key.toLowerCase()) {
                    keepGoing = false;
                    returnlist = [...lbl.labels];
                }
                if (lbl.labels != undefined) {
                    this.findLabelArray(lbl.labels, parent_key);
                }
            }
        });
        return returnlist;
    }

    pushLable(labels: IFormLable[], lbl: IFormLable) {
        let keepGoing = true;
        labels.forEach((l) => {
            if (keepGoing) {
                if (l.Key.toLowerCase() == lbl.Key.toLowerCase()) {
                    keepGoing = false;
                    lbl.isHidden = false;
                    labels.push(lbl);
                }
                if (l.labels != undefined) {
                    this.pushLable(l.labels, lbl);
                }
            }
        });
    }
    softDeleteLable(labels: IFormLable[], lbl: IFormLable) {
        let keepGoing = true;
        labels.forEach((l, i) => {
            if (keepGoing) {
                if (
                    l.Key.toLowerCase() == lbl.Key.toLowerCase() &&
                    l.index == lbl.index
                ) {
                    keepGoing = false;
                    l.isHidden = true;
                }
                if (l.labels != undefined) {
                    this.softDeleteLable(l.labels, lbl);
                }
            }
        });
    }

    hardDelete(labels: IFormLable[], lbl: IFormLable) {
        let keepGoing = true;
        labels.forEach((l, i) => {
            if (keepGoing) {
                if (
                    l.Key.toLowerCase() == lbl.Key.toLowerCase() &&
                    l.index == lbl.index
                ) {
                    keepGoing = false;
                    labels = [...labels.splice(i, 1)];
                }
                if (l.labels != undefined) {
                    this.hardDelete(l.labels, lbl);
                }
            }
        });
    }
    undoDelete(labels: IFormLable[], lbl: IFormLable) {
        let keepGoing = true;
        labels.forEach((l, i) => {
            if (keepGoing) {
                if (
                    l.Key.toLowerCase() == lbl.Key.toLowerCase() &&
                    l.index == lbl.index
                ) {
                    keepGoing = false;
                    l.isHidden = false;
                    l.cordinates = undefined;
                }
                if (l.labels != undefined) {
                    this.undoDelete(l.labels, lbl);
                }
            }
        });
    }
    pushLableIntoParent(
        labels: IFormLable[],
        parentname: string,
        lbl: IFormLable
    ) {
        let keepGoing = true;
        labels.forEach((l) => {
            if (keepGoing) {
                if (l.Key.toLowerCase() == parentname.toLowerCase()) {
                    keepGoing = false;
                    l.labels.push(lbl);
                }
                if (l.labels != undefined) {
                    this.pushLableIntoParent(l.labels, parentname, lbl);
                }
            }
        });
    }
    resetIndexes(labels: IFormLable[], currentIndex: number) {
        labels.forEach((lbl) => {
            if (lbl.labels == undefined) {
                if (lbl.index != null && lbl.index > currentIndex) {
                    lbl.index = lbl.index - 1;
                }
            } else {
                this.resetIndexes(lbl.labels, currentIndex);
            }
        });
    }

    GetAllLookUpsForHumanTaskPage(): Observable<any[]> {
        var queuelist = this.http.get<ITaskQueueItems>(this.rootUrl + "/api/services/app/TaskQueues/GetAll?MaxResultCount=1000", { headers: new HttpHeaders({ "Accept": "text/plain" }) });
        var assigntolist = this.http.get<IUser[]>(this.rootUrl + "/api/services/app/HumanTasks/GetAllUserForTableDropdown", { headers: new HttpHeaders({ "Accept": "text/plain" }) });
        var statuslist = this.http.get<ILookupitems>(this.rootUrl + "/api/services/app/Lookups/GetAll?Filter=HumanTaskStatus", { headers: new HttpHeaders({ "Accept": "text/plain" }) });
        var organizationunitlist = this.http.get<IListResultDtoOfOrganizationUnitDto>(this.rootUrl + "/api/services/app/OrganizationUnit/GetOrganizationUnits?MaxResultCount=100", { headers: new HttpHeaders({ "Accept": "text/plain" }) });
        return forkJoin([queuelist, assigntolist, statuslist, organizationunitlist]);
    }

    CreateNewTaskWithDocuments(formdata: FormData): Observable<any> {
        return this.http.post<any>(this.rootUrl + '/api/services/app/DocumentProcessingEngineService/SubmitDocumentsWithDetails', formdata)
            .pipe(catchError((error) => {
                if (error && error.error && error.error.error.message.indexOf('money')) {
                    this.toastr.error(error.error.error.message);
                } else {
                    return throwError(error);
                }
            }));
    }
    reProcessTasks(body: string[]): Observable<boolean> {
        return this.http.post<any>(this.rootUrl + "/api/services/app/HumanTasks/ReProcessTask", body);
    }
    submitDocumentsWithDetails(humanTaskId: string | null | undefined, files: FileParameter[] | null | undefined, isInvoice: string | null | undefined, taskQueueId: string | null | undefined, name: string | null | undefined, description: string | null | undefined, caseNumber: string | null | undefined, caseType: string | null | undefined, assignedTo: string | null | undefined, userId: string | null | undefined, dueOn: string | null | undefined, caseRecordId: string | null | undefined) {
        let url_ = this.rootUrl + "/api/services/app/DocumentProcessingEngineService/SubmitDocumentsWithDetails?";
        if (humanTaskId !== undefined && humanTaskId !== null)
            url_ += "humanTaskId=" + encodeURIComponent("" + humanTaskId) + "&";
        url_ = url_.replace(/[?&]$/, "");

        const content_ = new FormData();
        if (files !== null && files !== undefined)
            files.forEach(item_ => content_.append("files", item_.data, item_.fileName ? item_.fileName : "files"));
        if (isInvoice !== null && isInvoice !== undefined)
            content_.append("isInvoice", isInvoice.toString());
        if (taskQueueId !== null && taskQueueId !== undefined)
            content_.append("taskQueueId", taskQueueId.toString());
        if (name !== null && name !== undefined)
            content_.append("name", name.toString());
        if (description !== null && description !== undefined)
            content_.append("description", description.toString());
        if (caseNumber !== null && caseNumber !== undefined)
            content_.append("caseNumber", caseNumber.toString());
        if (caseType !== null && caseType !== undefined)
            content_.append("caseType", caseType.toString());
        if (assignedTo !== null && assignedTo !== undefined)
            content_.append("assignedTo", assignedTo.toString());
        if (userId !== null && userId !== undefined)
            content_.append("userId", userId.toString());
        if (dueOn !== null && dueOn !== undefined)
            content_.append("dueOn", dueOn.toString());
        if (caseRecordId !== null && caseRecordId !== undefined)
            content_.append("caseRecordId", caseRecordId.toString());

        // let options_: any = {
        //     body: content_,
        //     observe: "response",
        //     responseType: "blob",
        //     headers: new HttpHeaders({
        //         "Accept": "text/plain"
        //     })
        // };

        return this.http.post<any>(url_, content_,)
            .pipe(
                map((res) => {
                    return this.returnResult(res);
                })
            );
    }
    getAllTasks(
        filter: any,
        nameFilter: string,
        caseNumberFilter: string,
        caseTypeFilter: string,
        maxDueOnFilter: moment.Moment,
        minDueOnFilter: moment.Moment,
        dueTodayFilter: moment.Moment,
        statusFilter: string,
        assignedToFilter: string,
        taskQueueNameFilter: string,
        organizationUnitDisplayNameFilter: string,
        fileName: string,
        sorting: string,
        skipCount: number,
        maxResultCount: number): Observable<ITaskItem> {

        let url_ = this.rootUrl + "/api/services/app/HumanTasks/GetAll?";


        if (filter !== undefined && filter !== null && filter !== "")
            url_ += "Filter=" + encodeURIComponent("" + filter) + "&";
        if (nameFilter !== undefined && nameFilter !== null && nameFilter !== "")
            url_ += "NameFilter=" + encodeURIComponent("" + nameFilter) + "&";
        if (caseNumberFilter !== undefined && caseNumberFilter !== null && caseNumberFilter !== "")
            url_ += "CaseNumberFilter=" + encodeURIComponent("" + caseNumberFilter) + "&";
        if (caseTypeFilter !== undefined && caseTypeFilter !== null && caseTypeFilter !== "")
            url_ += "CaseTypeFilter=" + encodeURIComponent("" + caseTypeFilter) + "&";
        if (maxDueOnFilter !== undefined && maxDueOnFilter !== null)
            url_ += "MaxDueOnFilter=" + encodeURIComponent(maxDueOnFilter ? "" + moment(maxDueOnFilter).format("YYYY-MM-DD") : "") + "&";
        if (minDueOnFilter !== undefined && minDueOnFilter !== null)
            url_ += "MinDueOnFilter=" + encodeURIComponent(minDueOnFilter ? "" + moment(minDueOnFilter).format("YYYY-MM-DD") : "") + "&";
        if (dueTodayFilter !== undefined && dueTodayFilter !== null)
            url_ += "DueTodayFilter=" + encodeURIComponent(dueTodayFilter ? "" + moment(dueTodayFilter).format("YYYY-MM-DD") : "") + "&";
        if (statusFilter !== undefined && statusFilter !== null)
            url_ += "StatusFilter=" + encodeURIComponent("" + statusFilter) + "&";
        if (assignedToFilter !== undefined && assignedToFilter !== null && assignedToFilter !== "")
            url_ += "AssignedToFilter=" + encodeURIComponent("" + assignedToFilter) + "&";
        if (taskQueueNameFilter !== undefined && taskQueueNameFilter !== null && taskQueueNameFilter !== "")
            url_ += "TaskQueueNameFilter=" + encodeURIComponent("" + taskQueueNameFilter) + "&";
        if (organizationUnitDisplayNameFilter !== undefined && organizationUnitDisplayNameFilter !== null && organizationUnitDisplayNameFilter !== "")
            url_ += "OrganizationUnitDisplayNameFilter=" + encodeURIComponent("" + organizationUnitDisplayNameFilter) + "&";
        if (fileName !== undefined && fileName !== null && fileName !== "")
            url_ += "FileName=" + encodeURIComponent("" + fileName) + "&";
        if (sorting !== undefined && sorting !== null && sorting !== "")
            url_ += "Sorting=" + encodeURIComponent("" + sorting) + "&";
        if (skipCount === null)
            throw new Error("The parameter 'skipCount' cannot be null.");
        else if (skipCount !== undefined)
            url_ += "SkipCount=" + encodeURIComponent("" + skipCount) + "&";
        if (maxResultCount === null)
            throw new Error("The parameter 'maxResultCount' cannot be null.");
        else if (maxResultCount !== undefined)
            url_ += "MaxResultCount=" + encodeURIComponent("" + maxResultCount) + "&";
        url_ = url_.replace(/[?&]$/, "");
        return this.http.get<any>(url_)
            .pipe(map((res) => { return this.returnResult(res); }));


    }
    delete(id: string | undefined) {
        return this.http.delete<any>(this.rootUrl + "/api/services/app/HumanTasks/Delete?Id=" + id);
    }
    getAllOrganizationUnitForLookupTable(filter: string | null | undefined, sorting: string | null | undefined, skipCount: number | undefined, maxResultCount: number | undefined) {
        let url_ = this.baseUrl + "/api/services/app/HumanTasks/GetAllOrganizationUnitForLookupTable?";
        if (filter !== undefined && filter !== null)
            url_ += "Filter=" + encodeURIComponent("" + filter) + "&";
        if (sorting !== undefined && sorting !== null)
            url_ += "Sorting=" + encodeURIComponent("" + sorting) + "&";
        if (skipCount === null)
            throw new Error("The parameter 'skipCount' cannot be null.");
        else if (skipCount !== undefined)
            url_ += "SkipCount=" + encodeURIComponent("" + skipCount) + "&";
        if (maxResultCount === null)
            throw new Error("The parameter 'maxResultCount' cannot be null.");
        else if (maxResultCount !== undefined)
            url_ += "MaxResultCount=" + encodeURIComponent("" + maxResultCount) + "&";
        url_ = url_.replace(/[?&]$/, "");
        return this.http.get<any>(url_)
            .pipe(map((res) => { return this.returnResult(res); }));

    }
    getAllTaskQueueForLookupTable(filter: string | null | undefined, sorting: string | null | undefined, skipCount: number | undefined, maxResultCount: number | undefined) {
        let url_ = this.baseUrl + "/api/services/app/HumanTasks/GetAllTaskQueueForLookupTable?";
        if (filter !== undefined && filter !== null)
            url_ += "Filter=" + encodeURIComponent("" + filter) + "&";
        if (sorting !== undefined && sorting !== null)
            url_ += "Sorting=" + encodeURIComponent("" + sorting) + "&";
        if (skipCount === null)
            throw new Error("The parameter 'skipCount' cannot be null.");
        else if (skipCount !== undefined)
            url_ += "SkipCount=" + encodeURIComponent("" + skipCount) + "&";
        if (maxResultCount === null)
            throw new Error("The parameter 'maxResultCount' cannot be null.");
        else if (maxResultCount !== undefined)
            url_ += "MaxResultCount=" + encodeURIComponent("" + maxResultCount) + "&";
        url_ = url_.replace(/[?&]$/, "");

        return this.http.get<any>(url_)
            .pipe(map((res) => { return this.returnResult(res); }));

    }

    getTaskEmailById() {
        return this.http.get<ITaskEmail>(`${this.rootUrl}/api/services/app/Emails/GetEmailByHumanTaskId?Id=${this.taskDetails.humanTask.id}`)
            .pipe(map((res) => { return this.returnResult(res); }));
    }


    public ObjectMapper(target, source) {
        target = JSON.parse(JSON.stringify(source));
        return target;
    }

    // assignValueToHumanTask(res: IHumanTask) {
    //     this.taskDetails.humanTask.assignedTo = res.assignedTo;
    //     this.taskDetails.humanTask.caseNumber = res.caseNumber;
    //     this.taskDetails.humanTask.caseRecordId = res.caseRecordId;
    //     this.taskDetails.humanTask.caseType = res.caseType;
    //     this.taskDetails.humanTask.description = res.description;
    //     this.taskDetails.humanTask.documentSessionID = res.documentSessionID;
    //     this.taskDetails.humanTask.dueOn = res.dueOn;
    //     this.taskDetails.humanTask.id = res.id;
    //     this.taskDetails.humanTask.isInvoice = res.isInvoice;
    //     this.taskDetails.humanTask.lastModificationTime = res.lastModificationTime;
    //     this.taskDetails.humanTask.name = res.name;
    //     this.taskDetails.humanTask.organizationUnitId = res.organizationUnitId;
    //     this.taskDetails.humanTask.status = res.status;
    //     this.taskDetails.humanTask.taskQueueId = res.taskQueueId;
    // }

}
