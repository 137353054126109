import { PermissionCheckerService } from "abp-ng2-module";
import { AppSessionService } from "@shared/common/session/app-session.service";

import { Injectable } from "@angular/core";
import { AppMenu } from "./app-menu";
import { AppMenuItem } from "./app-menu-item";
import { id } from "@swimlane/ngx-charts";

@Injectable()
export class AppNavigationService {
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _appSessionService: AppSessionService
    ) {}

    getMenu(): AppMenu {
        return new AppMenu("MainMenu", "MainMenu", [
            new AppMenuItem(
                "Dashboard",
                "Pages.Administration.Host.Dashboard",
                "flaticon-line-graph",
                "/app/admin/hostDashboard",
                "Dashboard"
            ),
            // new AppMenuItem(
            //     "Dashboard",
            //     "Pages.Tenant.Dashboard",
            //     "flaticon-line-graph",
            //     "/app/main/dashboard",
            //     "Dashboard"
            // ),
            // new AppMenuItem(
            //     "Create Task",
            //     "Pages.HumanTasks.Create",
            //     "far fa-clipboard",
            //     "/app/tasks/createtask",
            //     "Create_Task"
            // ),
            new AppMenuItem(
                "Tasks",
                "Pages.HumanTasks",
                "far fa-file-alt",
                "/app/tasks/humantasks",
                "Tasks"
            ),

            // new AppMenuItem('Reports', '', 'flaticon-interface-8', '','' ,[], [
            // new AppMenuItem("Tasks Verified By User (hr)", 'Pages.HumanTasks', "far fa-file-alt", "/app/main/tasksVerifiedByUserByHour"),
            new AppMenuItem(
                "Case Management",
                "Pages.CaseRecords",
                "fas fa-chalkboard-teacher",
                "/app/case/caselist",
                "CaseManagement"
            ),

            // new AppMenuItem('Reports', '', 'flaticon-interface-8', '', '', [], [
            //     new AppMenuItem("Tasks Verified By User (hr)", 'Pages.HumanTasks', "far fa-file-alt", "/app/main/tasksVerifiedByUserByHour"),

            // ]),

            new AppMenuItem(
                "Canvas",
                "Pages.Administration.Forms.Create",
                "fas fa-chalkboard-teacher",
                "/app/canvas",
                "Canvas"
            ),

            // new AppMenuItem(
            //     "Reports",
            //     "Pages.HumanTasks",
            //     "flaticon-interface-8",
            //     "",
            //     "Administration",
            //     [],
            //     [
            //         new AppMenuItem(
            //             "Account Usage",
            //             "Pages.TenantConfigs",
            //             "fas fa-chalkboard-teacher",
            //             "/app/reports/accountusagereport",
            //             "accountusagereport"
            //         ),
            //         new AppMenuItem(
            //             "Pending Tasks By User",
            //             "Pages.TenantConfigs",
            //             "fas fa-chalkboard-teacher",
            //             "/app/reports/pendingtaskbyuserreport",
            //             "pendingtaskbyuserreport"
            //         ),
            //         new AppMenuItem(
            //             "Template Reports",
            //             "Pages.Administration",
            //             "fas fa-chalkboard-teacher",
            //             "/app/reports/templatestaskbyuser",
            //             "Template_Reports"
            //         ),
            //         new AppMenuItem(
            //             "Individual Task Reports",
            //             "Pages.Administration",
            //             "fas fa-chalkboard-teacher",
            //             "/app/reports/IndividualTaskReports",
            //             "Individual_Task_Reports"
            //         ),
            //         new AppMenuItem(
            //             "Task Time Reports",
            //             "Pages.Administration",
            //             "fas fa-chalkboard-teacher",
            //             "/app/reports/TimeTaskReports",
            //             "Task_Time_Reports"
            //         ),
            //     ]

            // ),

            new AppMenuItem(
                "Tenants",
                "Pages.Tenants.Create",
                "flaticon-list-3",
                "/app/admin/tenants",
                "Tenants"
            ),
            new AppMenuItem(
                "Editions",
                "Pages.Editions",
                "flaticon-app",
                "/app/admin/editions",
                "Editions"
            ),
            new AppMenuItem(
                "Administration",
                "",
                "flaticon-interface-8",
                "",
                "Administration",
                [],
                [
                    new AppMenuItem(
                        "Teams",
                        "Pages.Administration.OrganizationUnits",
                        "flaticon-map",
                        "/app/admin/team",
                        "OrganizationUnits"
                    ),
                    new AppMenuItem(
                        "Tasks Queues",
                        "Pages.Administration.TaskQueues",
                        "flaticon-book",
                        "/app/admin/tasksQueues",
                        "Tasks_Queues"
                    ),
                    new AppMenuItem(
                        "Tenant Configurations",
                        "Pages.TenantConfigs.Create",
                        "flaticon-book",
                        "/app/admin/tenantconfiguration",
                        "Tenant_Configurations"
                    ),
                    // new AppMenuItem(
                    //     "Templates",
                    //     "Pages.Administration.TemplateLibraries",
                    //     "flaticon-folder",
                    //     "/app/admin/templates",
                    //     "Templates"
                    // ),
                    new AppMenuItem(
                        "Lookups ",
                        "Pages.Administration.Lookups.Create",
                        "flaticon-book",
                        "/app/admin/lookups",
                        "Lookups"
                    ),
                    new AppMenuItem(
                        "Field Validation",
                        "Pages.Administration.FieldValidations.Create",
                        "flaticon-book",
                        "/app/admin/fieldvalidation",
                        "Field_Validation"
                    ),
                    new AppMenuItem(
                        "Roles",
                        "Pages.Administration.Roles",
                        "flaticon-suitcase",
                        "/app/admin/roles",
                        "Roles"
                    ),
                    new AppMenuItem(
                        "Users",
                        "Pages.Administration.Users",
                        "flaticon-users",
                        "/app/admin/users",
                        "Users"
                    ),
                    new AppMenuItem(
                        "Languages",
                        "Pages.Administration.Languages",
                        "flaticon-tabs",
                        "/app/admin/languages",
                        "Languages",
                        ["/app/admin/languages/{name}/texts"]
                    ),
                    new AppMenuItem(
                        "AuditLogs",
                        "Pages.Administration.AuditLogs",
                        "flaticon-folder-1",
                        "/app/admin/auditLogs",
                        "AuditLogs"
                    ),
                    // new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'flaticon-lock', '/app/admin/maintenance'),
                    // new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                    // new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                    // new AppMenuItem('WebhookSubscriptions', 'Pages.Administration.WebhookSubscription', 'flaticon2-world', '/app/admin/webhook-subscriptions'),
                    // new AppMenuItem('DynamicParameters', '', 'flaticon-interface-8', '', [], [
                    //     new AppMenuItem('Definitions', 'Pages.Administration.DynamicParameters', '', '/app/admin/dynamic-parameter'),
                    //     new AppMenuItem('EntityDynamicParameters', 'Pages.Administration.EntityDynamicParameters', '', '/app/admin/entity-dynamic-parameter'),
                    // ]),
                    // new AppMenuItem('BalanceClosed', 'Pages.Administration.OrganizationUnits', 'flaticon-settings', '/app/admin/balaceclosed'),
                    new AppMenuItem(
                        "Account Balance",
                        "Pages.TenantConfigs",
                        "fa fa-credit-card",
                        "/app/admin/balancecredits",
                        "Account_Balance"
                    ),
                    new AppMenuItem(
                        "Case Records",
                        "Pages.Administration.Host.Dashboard",
                        "flaticon-interface-8",
                        "",
                        "",
                        [],
                        [
                            new AppMenuItem(
                                "Case Record Fields",
                                "Pages.Administration.Host.Dashboard",
                                "flaticon-book",
                                "/app/admin/casefields",
                                "caseFields"
                            ),
                            new AppMenuItem(
                                "Custom Case Record Fields",
                                "Pages.Administration.Host.Dashboard",
                                "flaticon-book",
                                "/app/admin/customcasefields",
                                "customCaseFields"
                            ),
                        ]
                    ),
                    new AppMenuItem(
                        "Check List Template",
                        "Pages.Administration.ChecklistTemplates",
                        "fas fa-chalkboard-teacher",
                        "/app/admin/checklist",
                        "checklist"
                    ),
                    // new AppMenuItem(
                    //     "Check List Template",
                    //     "Pages.Administration.Host.Dashboard",
                    //     "flaticon-interface-8",
                    //     "",
                    //     "",
                    //     [],
                    //     [

                    // new AppMenuItem(
                    //     "Check List Template Items",
                    //     "Pages.Administration.ChecklistTemplateItems",
                    //     "fas fa-chalkboard-teacher",
                    //     "/app/admin/checklistitems"
                    // ),
                    //     ]
                    // ),

                    // new AppMenuItem(
                    //     "Check List",
                    //     "Pages.TenantConfigs",
                    //     "fas fa-chalkboard-teacher",
                    //     "/app/admin/checklist"
                    // ),

                    // new AppMenuItem('BalanceDebits', 'Pages.Administration.OrganizationUnits', 'flaticon-settings', '/app/admin/balacedebits'),

                    //new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'flaticon-settings', '/app/admin/hostSettings'),
                    //new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'flaticon-settings', '/app/admin/tenantSettings')
                ]
            ),
            //new AppMenuItem('DemoUiComponents', 'Pages.DemoUiComponents', 'flaticon-shapes', '/app/admin/demo-ui-components')
        ]);
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (
                subMenuItem.permissionName === "" ||
                subMenuItem.permissionName === null
            ) {
                if (subMenuItem.route) {
                    return true;
                }
            } else if (
                this._permissionCheckerService.isGranted(
                    subMenuItem.permissionName
                )
            ) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                let isAnyChildItemActive =
                    this.checkChildMenuItemPermission(subMenuItem);
                if (isAnyChildItemActive) {
                    return true;
                }
            }
        }
        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName ===
                "Pages.Administration.Tenant.SubscriptionManagement" &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (
            menuItem.permissionName &&
            !this._permissionCheckerService.isGranted(menuItem.permissionName)
        ) {
            hideMenuItem = true;
        }

        if (
            this._appSessionService.tenant ||
            !abp.multiTenancy.ignoreFeatureCheckForHostUsers
        ) {
            if (
                menuItem.hasFeatureDependency() &&
                !menuItem.featureDependencySatisfied()
            ) {
                hideMenuItem = true;
            }
        }

        if (!hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }

    /**
     * Returns all menu items recursively
     */
    getAllMenuItems(): AppMenuItem[] {
        let menu = this.getMenu();
        let allMenuItems: AppMenuItem[] = [];
        menu.items.forEach((menuItem) => {
            allMenuItems = allMenuItems.concat(
                this.getAllMenuItemsRecursive(menuItem)
            );
        });

        return allMenuItems;
    }

    private getAllMenuItemsRecursive(menuItem: AppMenuItem): AppMenuItem[] {
        if (!menuItem.items) {
            return [menuItem];
        }

        let menuItems = [menuItem];
        menuItem.items.forEach((subMenu) => {
            menuItems = menuItems.concat(
                this.getAllMenuItemsRecursive(subMenu)
            );
        });

        return menuItems;
    }
}
