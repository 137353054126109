import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { XmlHttpRequestHelper } from '@shared/helpers/XmlHttpRequestHelper';
import { LocalStorageService } from '@shared/utils/local-storage.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from 'environments/environment';

@Injectable()
export class AppAuthService {
    private _oidcSecurityService:OidcSecurityService
    constructor(oidcSecurityService:OidcSecurityService){
        this._oidcSecurityService = oidcSecurityService;
    }

    logout(reload?: boolean, returnUrl?: string): void {
        this._oidcSecurityService.logoff();
        // this.oidcSecurityService.logoffAndRevokeTokens()
        // .subscribe((result) => {            
            localStorage.clear();
             localStorage.clear();
             sessionStorage.clear();
             sessionStorage.clear();
            abp.auth.clearToken();
            abp.auth.clearRefreshToken();
            new LocalStorageService().removeItem(AppConsts.authorization.encrptedAuthTokenName);
        // });
        // let customHeaders = {
        //     [abp.multiTenancy.tenantIdCookieName]: abp.multiTenancy.getTenantIdCookie(),
        //     'Authorization': 'Bearer ' + abp.auth.getToken()
        // };

        // XmlHttpRequestHelper.ajax(
        //     'GET',
        //     environment.AUTH_ISSUER + '/connect/endsession?id_token_hint='+abp.auth.getToken(),
        //     customHeaders,
        //     null,
        //     () => {
        //         abp.auth.clearToken();
        //         abp.auth.clearRefreshToken();
        //         new LocalStorageService().removeItem(AppConsts.authorization.encrptedAuthTokenName);
        //         if (reload !== false) {
        //             if (returnUrl) {
        //                 location.href = returnUrl;
        //             } else {
        //                 location.href = '';
        //             }
        //         }
        //     }
        // );
    }
}
