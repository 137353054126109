import { NgModule } from "@angular/core";
import {
    NavigationCancel,
    NavigationEnd,
    RouteConfigLoadEnd,
    RouteConfigLoadStart,
    Router,
    RouterModule,
} from "@angular/router";
import { AppComponent } from "./app.component";
import { AppRouteGuard } from "./shared/common/auth/auth-route-guard";
import { NotificationsComponent } from "./shared/layout/notifications/notifications.component";
import { NgxSpinnerService } from "ngx-spinner";
import { TasksDeactivateGuard } from "./tasks/guards/tasks-deactivate.guard";

@NgModule({
    imports: [
        RouterModule.forChild([
            {
                path: "app",
                component: AppComponent,
                canActivate: [AppRouteGuard],
                canActivateChild: [AppRouteGuard],
                children: [
                    {
                        path: "",
                        children: [
                            {
                                path: "notifications",
                                component: NotificationsComponent,
                            },
                            {
                                path: "",
                                redirectTo: "/app/tasks/humantasks",
                                pathMatch: "full",
                            },
                        ],
                    },
                    {
                        path: "canvas",
                        loadChildren: () =>
                            import("app/canvas/canvas.module").then(
                                (m) => m.CanvasModule
                            ), //Lazy load admin module
                        data: { preload: true },
                        canLoad: [AppRouteGuard],
                    },
                    {
                        path: "case",
                        loadChildren: () =>
                            import("app/case/case.module").then(
                                (m) => m.CaseModule
                            ), //Lazy load admin module
                        data: { preload: true },
                        // canLoad: [AppRouteGuard]
                    },
                    {
                        path: "main",
                        loadChildren: () =>
                            import("app/main/main.module").then(
                                (m) => m.MainModule
                            ), //Lazy load main module
                        data: { preload: true },
                    },
                    // {
                    //     path: "reports",
                    //     loadChildren: () =>
                    //         import("app/reports/reports.module").then(
                    //             (m) => m.ReportsModule
                    //         ), //Lazy load main module
                    //     data: { preload: true },
                    // },
                    {
                        path: "admin",
                        loadChildren: () =>
                            import("app/admin/admin.module").then(
                                (m) => m.AdminModule
                            ), //Lazy load admin module
                        data: { preload: true },
                        canLoad: [AppRouteGuard],
                    },
                    // {
                    //     path: "load",
                    //     loadChildren: () =>
                    //         import("app/documentList/documentList.module").then(
                    //             (m) => m.DocumentListModule
                    //         ), //Lazy load admin module
                    //     canLoad: [AppRouteGuard],
                    //     data: { preload: true },
                    // },
                    {
                        path: "tasks",
                        loadChildren: () =>
                            import("app/tasks/tasks.module").then(
                                (m) => m.TasksModule
                            ),
                        canDeactivate: [TasksDeactivateGuard],
                        canLoad: [AppRouteGuard],
                        data: { preload: true },
                    },
                    {
                        path: "**",
                        redirectTo: "notifications",
                    },
                ],
            },
            {
                path: "salesforce",
                loadChildren: () =>
                    import("app/tasks/tasks.module").then((m) => m.TasksModule), //Lazy load admin module
                data: { preload: true },
            },
        ]),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(
        private router: Router,
        private spinnerService: NgxSpinnerService
    ) {
        router.events.subscribe((event) => {
            if (event instanceof RouteConfigLoadStart) {
                spinnerService.show();
            }

            if (event instanceof RouteConfigLoadEnd) {
                spinnerService.hide();
            }

            if (event instanceof NavigationEnd) {
                document
                    .querySelector("meta[property=og\\:url")
                    .setAttribute("content", window.location.href);
            }
            if (event instanceof NavigationCancel) {
                sessionStorage.setItem("canceledUrl", event.url);
            }
        });
    }
}
