import { Injector, Component, ViewEncapsulation, Inject } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DOCUMENT } from '@angular/common';
import { DefaultBrandService } from './default-brand.service';

@Component({
    templateUrl: './default-brand.component.html',
    selector: 'default-brand',
    encapsulation: ViewEncapsulation.None
})
export class DefaultBrandComponent extends AppComponentBase {
    isLogoVisible = false;
    // defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-on-' + this.currentTheme.baseSettings.menu.asideSkin + '.svg';
    defaultLogo = AppConsts.appBaseUrl + '/assets/common/images/app-logo-dark.png';

    remoteServiceBaseUrl: string = AppConsts.remoteServiceBaseUrl;

    constructor(
        injector: Injector,
        @Inject(DOCUMENT) private document: Document,
        private defaultBrandService: DefaultBrandService
    ) {
        super(injector);
    }

    toggleLeftAside(): void {
        // this.isLogoVisible = !this.isLogoVisible;
        // this.defaultBrandService.onLeftNavCollapse(this.isLogoVisible)
       const value = this.document.body.classList.toggle('aside-minimize');
       this.defaultBrandService.onLeftNavCollapse(value);
        this.triggerAsideToggleClickEvent();
    }

    triggerAsideToggleClickEvent(): void {
        abp.event.trigger('app.kt_aside_toggler.onClick');
    }
}
