import { Injectable, Inject } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError, Observable, forkJoin } from 'rxjs';
import { AppConsts } from '@shared/AppConsts';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TxWidgetServiceService {

  private http: HttpClient;
  rootUrl: string = AppConsts.remoteServiceBaseUrl;

  constructor(@Inject(HttpClient) http: HttpClient) {

    this.http = http;
  }
  GetAllTaskByDate(date: string): Observable<any> {
    return this.http.get<any>(this.rootUrl + "/api/services/app/HumanTasks/GetAllTaskByDate?date="+date,
    { headers: new HttpHeaders({ "Accept": "text/plain" }) })
    .pipe(catchError((error) => { return throwError(error); }));
  }
  GetAllLookUpsForHumanTaskPage(): Observable<any> {
    return this.http.get<any>(this.rootUrl + "/api/services/app/TaskQueues/GetAll?MaxResultCount=1000", { headers: new HttpHeaders({ "Accept": "text/plain" }) })
      .pipe(catchError((error) => { return throwError(error); }));
  }
  GetTaskCountByStatus(taskQueueId) {
    // /api/services/app/DashboardWidgets/GetTaskCountByStatus
    return this.http.get<any>(this.rootUrl + "/api/services/app/DashboardWidgets/GetTaskCountByStatus" + taskQueueId, { headers: new HttpHeaders({ "Accept": "text/plain" }) })
      .pipe(catchError((error) => { return throwError(error); }))
  }

  GetAgeOfCompletedTasks(taskQueueId) {
    return this.http.get<any>(this.rootUrl + "/api/services/app/DashboardWidgets/GetAgeOfCompletedTasks" + taskQueueId, { headers: new HttpHeaders({ "Accept": "text/plain" }) })
      .pipe(catchError((error) => { return throwError(error); }))
  }

  GetAgeOfPendingTasks(taskQueueId) {
    return this.http.get<any>(this.rootUrl + "/api/services/app/DashboardWidgets/GetAgeOfPendingTasks" + taskQueueId, { headers: new HttpHeaders({ "Accept": "text/plain" }) })
      .pipe(catchError((error) => { return throwError(error); }))
  }

  GetCompletedTaskByUser() {
    return this.http.get<any>(this.rootUrl + "/api/services/app/DashBoardWidgets/GetCompletedTasksByUser", { headers: new HttpHeaders({ "Accept": "text/plain" }) })
      .pipe(catchError((error) => { return throwError(error); }))
  }

  GetPendingTaskByUser() {
    return this.http.get<any>(this.rootUrl + "/api/services/app/DashBoardWidgets/GetPendingTasksByUser", { headers: new HttpHeaders({ "Accept": "text/plain" }) })
      .pipe(catchError((error) => { return throwError(error); }))
  }
}
