<div>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header [title]="'Tasks' | localize" [description]="'Verified by user by hour' | localize">
            <div role="actions">
            </div>
        </sub-header>

        <div [class]="containerClass">
            <div class="card card-custom">
                <div class="card-body">
                    <div class="col-md-3">

                        <input (appTrim)="$event" required class="form-control m-input" placeholder="Select Date"
                            type="datetime" (dateChange)="DateChanged()" bsDatepicker datePickerMomentModifier
                            [(ngModel)]="requiredDate" (keydown)="disableDateEntry($event,dueOnPicker)"
                            #dueOnPicker="bsDatepicker" [bsConfig]="bsConfig">


                    </div>
                    <br>
                    <!-- <pre> {{selectedItems | json}}</pre> -->
                    <div class="row align-items-center">


                        <div class="primeng-datatable-container col-12" [busyIf]="primengTableHelper.isLoading">
                            <p-table selectionMode="multiple" [(selection)]="selectedItems" #dataTable
                                [value]="primengTableHelper.records" [rows]="10" [paginator]="false" [lazy]="false"
                                [scrollable]="true" ScrollWidth="100%" [responsive]="primengTableHelper.isResponsive"
                                [resizableColumns]="primengTableHelper.resizableColumns" [columns]="headerCols">
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th *ngFor="let col of columns">
                                            {{col.header}}
                                        </th>
                                    </tr>
                                </ng-template>

                                <ng-template pTemplate="body" let-record>
                                    <tr>
                                        <td>
                                            <span class="ui-column-title"> {{l('AssignedTo')}}</span>
                                            {{record.AssignedTo}}
                                        </td>
                                        <td *ngFor="let val of record.TaskVerficationByHour">
                                            <!-- <span class="ui-column-title"> {{l('TimestampHour')}}</span> -->
                                            <a class="pointer-class"> {{ val.length }}</a>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>

                            <div class="primeng-no-data" *ngIf="primengTableHelper.totalRecordsCount == 0">
                                {{l('NoData')}}
                            </div>
                            <div>
                                <!-- <div class="primeng-paging-container">
                              <p-paginator [rows]="10" #paginator
                                  (onPageChange)="GetTaskVerifiedByUser($event)"
                                  [totalRecords]="primengTableHelper.totalRecordsCount"
                                  [rowsPerPageOptions]="primengTableHelper.predefinedRecordsCountPerPage">
                              </p-paginator>
                              <span class="total-records-count">
                                  {{l('TotalRecordsCount', primengTableHelper.totalRecordsCount)}}
                              </span>
                          </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>







<!-- <div class="card card-custom h-100">
  <div class="card-header align-items-center border-0">
    <h3 class="card-title align-items-start flex-column">
      <span class="font-weight-bolder text-dark">
        Pending Tasks By User
      </span>
    </h3>
  </div>
  <div class="card-body noTopPadd">
    <div class="row" style="max-height: 200px">
      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tasks </th>
          <td mat-cell *matCellDef="let row"> {{row.value}} </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching this filter</td>
        </tr>
      </table>

      <mat-paginator [pageSize]="5"></mat-paginator>
    </div>
  </div>
</div> -->
