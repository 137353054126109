import { Component, Injector, OnInit } from '@angular/core';
import { MessagingService } from '@app/@core/messaging/messaging.service';
import { DashboardWidgetsServiceProxy } from '@shared/service-proxies/service-proxies';
import { DashboardCustomizationConst } from '../../DashboardCustomizationConsts';
import { WidgetComponentBase } from '../WidgetComponentBase';

@Component({
    selector: 'app-Organization-Status',
    templateUrl: './Organization-Status.component.html',
    styleUrls: ['./Organization-Status.component.css']
})
export class OrganizationStatusComponent extends WidgetComponentBase implements OnInit {
    single: any[];
    public view: any[] = [700, 400];
    public showXAxis = true;
    public showYAxis = true;
    public gradient = false;
    public showLegend = false;
    public showXAxisLabel = true;
    public xAxisLabel: "Years";
    public showYAxisLabel = true;
    public yAxisLabel: "Salary";
    public graphDataChart: any[];
    public colorScheme = {
        domain: ['#A10A28', '#5AA454',]
    };
    constructor(

        injector: Injector, private widgetservice: DashboardWidgetsServiceProxy, private messageservice: MessagingService) {
        super(injector);
    }
    onSelect(event) {
        console.log(event);
    }
    ngOnInit() {
        this.GetOrganizationStatus();
    }

    GetOrganizationStatus() {
        this.widgetservice.getOrganizationStatus()
            .subscribe(data => {
                this.single = data.map(res => ({ name: res.status, series: res.valuePairs }));
            })
    }
}
