import { Component, OnInit, Injector } from '@angular/core';
import { TxWidgetServiceService } from '../../TextExtractor-Widget-Services/Tx-widget-service.service';
import { WidgetComponentBase } from "../WidgetComponentBase";
import { Subject } from 'rxjs';
import { clear } from 'localforage';
import { result } from 'lodash';

@Component({
    selector: 'tX-widget-task-count-by-status',
    templateUrl: './widget-task-count-by-status.component.html',
    styleUrls: ['./widget-task-count-by-status.component.css']
})
export class WidgetTaskCountByStatusComponent extends WidgetComponentBase implements OnInit {

    // select: any = [];
    QueuesList: Subject<any> = new Subject();
    selectedQueue: any;
    selectedQueueId: string;
    slectedQueueValue: string;



    single: any[];
    // view: any[] = [300, 400];

    // options
    gradient: boolean = true;
    showLegend: boolean = true;
    showLabels: boolean = true;

    isDoughnut: boolean = false;
    legendPosition: string = 'below';
    colorScheme = {
        domain: ['#A10A28', '#5AA454', '#C7B42C', '#AAAAAA', '#5AA454', '#C7B42C', '#A10A28', '#5AA454', '#C7B42C', '#AAAAAA']
    };
    constructor(injector: Injector,
        private widgetservice: TxWidgetServiceService
    ) {

        super(injector);
        this.single = [];
    }
    ngOnInit() {
        this.getAllRequiredLookUps();


    }
    onSelect(data): void {
    }

    onActivate(data): void {
    }

    onDeactivate(data): void {
    }


    selectedqueue(selqueuename: any) {
        // this.selectedQueueId = selqueuename.id;
        // this.slectedQueueValue = selqueuename.name;
        this.getStatusByStatus(selqueuename.id);

    }


    getAllRequiredLookUps() {
        this.widgetservice.GetAllLookUpsForHumanTaskPage().subscribe(
            res => {
                this.QueuesList.next(res.result.items);
                // this.select = res.result.items
                // this.selectedQueue = {
                //   taskQueue: {
                //     id: res.result.items[0].taskQueue.id,
                //     name: res.result.items[0].taskQueue.name
                //   }
                // }

                // this.slectedQueueValue = res.result.items[0].taskQueue.name;
                this.getStatusByStatus('');
            }
        )
    }
    getStatusByStatus(id) {
        if (id != '') {
            id = '?taskQueueIds=' + id;
        }
        this.widgetservice.GetTaskCountByStatus(id).subscribe(
            data => {
                this.single = data.result;
            }
        );
    }
}
