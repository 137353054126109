<div class="card card-custom h-100">
    <div class="card-header align-items-center border-0">
        <h3 class="card-title align-items-start flex-column">
            <span class="font-weight-bolder text-dark">
                Task Count By Status
            </span>
        </h3>
    </div>
    <div class="card-body noTopPadd dropdownlabel">
        <div class="row" style="max-height: 200px" *ngIf="QueuesList | async as list">
            <div class="col-md-12 col-sm-12 col-xs-12 dashboardDropdown">
                <label for="quue">
                <ng-select [items]="list" name="qlist" (clear)="selectedqueue('')" [clearable]="false"
                    (change)="selectedqueue(selectedQueue.taskQueue)" bindLabel="taskQueue.name" placeholder="All Queues"
                    [(ngModel)]="selectedQueue">
                </ng-select>
                <!-- <ng-select [(ngModel)]="selectedQueue" placeholder="All Queues" aria-label="name">
                    <ng-option *ngFor="let item of list" [value]="item" name="qlist" (clear)="selectedqueue('')" class="ngselect"
                        (change)="selectedqueue(selectedQueue.taskQueue)" bindLabel="taskQueue.name">{{item.taskQueue.name}}
                    </ng-option>
                </ng-select> -->
            </label>
                <ngx-charts-pie-chart [results]="single" [gradient]="false" [labels]="true" [doughnut]="isDoughnut"
                    (select)="onSelect($event)" (activate)="onActivate($event)" (deactivate)="onDeactivate($event)">
                </ngx-charts-pie-chart>
            </div>
        </div>
    </div>
</div>
