<div class="card card-custom h-100">
  <div class="card-header align-items-center border-0">
    <h3 class="card-title align-items-start flex-column">
      <span class="font-weight-bolder text-dark">
        Completed Tasks By User
      </span>
    </h3>
  </div>
  <div class="card-body noTopPadd">
    <div class="row" style="max-height: 200px">
      <table mat-table [dataSource]="dataSource" matSort>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let row"> {{row.name}} </td>
        </ng-container>

        <!-- value Column -->
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Tasks </th>
          <td mat-cell *matCellDef="let row"> {{row.value}} </td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching this filter</td>
        </tr>
      </table>

      <mat-paginator [pageSize]="5"></mat-paginator>
    </div>
  </div>
</div>
