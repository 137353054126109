import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DefaultBrandService {
isVisible= false;
  constructor() { }

  onLeftNavCollapse(value: boolean): void{
        this.isVisible = value;
  }

  leftNaveCollapsed(): boolean{
      return this.isVisible;
  }
}
