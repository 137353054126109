import {
    Component,
    Injector,
    OnInit,
    ViewChild,
    ChangeDetectorRef,
} from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { UrlHelper } from "@shared/helpers/UrlHelper";
import { SubscriptionStartType } from "@shared/service-proxies/service-proxies";
// import { ChatSignalrService } from 'app/shared/layout/chat/chat-signalr.service';
import * as moment from "moment";
import { AppComponentBase } from "shared/common/app-component-base";
// import { SignalRHelper } from 'shared/helpers/SignalRHelper';
import { LinkedAccountsModalComponent } from "@app/shared/layout/linked-accounts-modal.component";
import { UserDelegationsModalComponent } from "@app/shared/layout/user-delegations-modal.component";
import { LoginAttemptsModalComponent } from "@app/shared/layout/login-attempts-modal.component";
import { ChangePasswordModalComponent } from "@app/shared/layout/profile/change-password-modal.component";
import { ChangeProfilePictureModalComponent } from "@app/shared/layout/profile/change-profile-picture-modal.component";
import { MySettingsModalComponent } from "@app/shared/layout/profile/my-settings-modal.component";
import { NotificationSettingsModalComponent } from "@app/shared/layout/notifications/notification-settings-modal.component";
import { UserNotificationHelper } from "@app/shared/layout/notifications/UserNotificationHelper";
import { SharedService } from "./shared/shared.service";
import { Router } from "@angular/router";

@Component({
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.less", "./app.component.css"],
})
export class AppComponent extends AppComponentBase implements OnInit {
    subscriptionStartType = SubscriptionStartType;
    theme: string;
    installationMode = true;

    @ViewChild("loginAttemptsModal", { static: true })
    loginAttemptsModal: LoginAttemptsModalComponent;
    @ViewChild("linkedAccountsModal")
    linkedAccountsModal: LinkedAccountsModalComponent;
    @ViewChild("userDelegationsModal", { static: true })
    userDelegationsModal: UserDelegationsModalComponent;
    @ViewChild("changePasswordModal", { static: true })
    changePasswordModal: ChangePasswordModalComponent;
    @ViewChild("changeProfilePictureModal", { static: true })
    changeProfilePictureModal: ChangeProfilePictureModalComponent;
    @ViewChild("mySettingsModal", { static: true })
    mySettingsModal: MySettingsModalComponent;
    @ViewChild("notificationSettingsModal", { static: true })
    notificationSettingsModal: NotificationSettingsModalComponent;
    @ViewChild("chatBarComponent") chatBarComponent;
    isQuickThemeSelectEnabled: boolean = this.setting.getBoolean(
        "App.UserManagement.IsQuickThemeSelectEnabled"
    );
    IsSessionTimeOutEnabled: boolean =
        this.setting.getBoolean(
            "App.UserManagement.SessionTimeOut.IsEnabled"
        ) && this.appSession.userId != null;
    isLoading = false;
    public constructor(
        private router: Router,
        injector: Injector,
        // private _chatSignalrService: ChatSignalrService,
        private sharedService: SharedService,
        public cdr: ChangeDetectorRef,
        private _userNotificationHelper: UserNotificationHelper
    ) {
        super(injector);

        this.sharedService.isLoadingSource.subscribe((loading) => {
            this.isLoading = loading;
            this.cdr.detectChanges();
        });
    }

    ngOnInit(): void {
        this._userNotificationHelper.settingsModal =
            this.notificationSettingsModal;
        this.theme = abp.setting
            .get("App.UiManagement.Theme")
            .toLocaleLowerCase();
        this.installationMode = UrlHelper.isInstallUrl(location.href);
        this.registerModalOpenEvents();
    }

    subscriptionStatusBarVisible(): boolean {
        return (
            this.appSession.tenantId > 0 &&
            (this.appSession.tenant.isInTrialPeriod ||
                this.subscriptionIsExpiringSoon())
        );
    }

    subscriptionIsExpiringSoon(): boolean {
        if (this.appSession.tenant.subscriptionEndDateUtc) {
            return (
                moment()
                    .utc()
                    .add(AppConsts.subscriptionExpireNootifyDayCount, "days") >=
                moment(this.appSession.tenant.subscriptionEndDateUtc)
            );
        }

        return false;
    }

    registerModalOpenEvents(): void {
        abp.event.on("app.show.loginAttemptsModal", () => {
            this.loginAttemptsModal.show();
        });

        abp.event.on("app.show.linkedAccountsModal", () => {
            this.linkedAccountsModal.show();
        });

        abp.event.on("app.show.userDelegationsModal", () => {
            this.userDelegationsModal.show();
        });

        abp.event.on("app.show.changePasswordModal", () => {
            this.changePasswordModal.show();
        });

        abp.event.on("app.show.changeProfilePictureModal", () => {
            this.changeProfilePictureModal.show();
        });

        abp.event.on("app.show.mySettingsModal", () => {
            this.mySettingsModal.show();
        });
    }

    getRecentlyLinkedUsers(): void {
        abp.event.trigger("app.getRecentlyLinkedUsers");
    }

    onMySettingsModalSaved(): void {
        abp.event.trigger("app.onMySettingsModalSaved");
    }
}
