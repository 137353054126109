import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConsts } from '@shared/AppConsts';
import { ToastrService } from 'ngx-toastr';
import { AppSessionService } from './session/app-session.service';

@Injectable({
    providedIn: 'root'
})
export class BaseService {

    protected http: HttpClient;
    protected sanitizer: DomSanitizer;
    protected appSession: AppSessionService;
    protected toastr: ToastrService
    protected rootUrl: string = AppConsts.remoteServiceBaseUrl;
    constructor(injector: Injector) {
        this.http = injector.get(HttpClient);
        this.sanitizer = injector.get(DomSanitizer);
        this.appSession = injector.get(AppSessionService);
        this.toastr = injector.get(ToastrService);
    }
    returnResult(res) {
        return res.result;
    }
    getActiveUserName() {
        return this.appSession.user.name + (this.appSession.user.surname != "" ? " " + this.appSession.user.surname : "");
    }
}
