import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { WidgetComponentBase } from "../WidgetComponentBase";
import { TxWidgetServiceService } from '../../TextExtractor-Widget-Services/Tx-widget-service.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'tX-widget-pending-tasks-by-user',
  templateUrl: './widget-pending-tasks-by-user.component.html',
  styleUrls: ['./widget-pending-tasks-by-user.component.css']
})
export class WidgetPendingTasksByUserComponent extends WidgetComponentBase implements OnInit {
  displayedColumns: string[] = ['name', 'value'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(injector: Injector,
    private widgetservice: TxWidgetServiceService) {
    super(injector);

  }

  ngOnInit() {


    this.GetPendingTaskByUser();


  }

  GetPendingTaskByUser() {
    this.widgetservice.GetPendingTaskByUser().subscribe(
      res => {
        this.dataSource = new MatTableDataSource(res.result);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    );
  }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();

  //   if (this.dataSource.paginator) {
  //     this.dataSource.paginator.firstPage();
  //   }
  // }


  //   createNewUser(id: number): any {
  //     const name = NAMES[Math.round(Math.random() * (NAMES.length - 1))] + ' ' +
  //         NAMES[Math.round(Math.random() * (NAMES.length - 1))].charAt(0) + '.';

  //     return {
  //       id: id.toString(),
  //       name: name,
  //       progress: Math.round(Math.random() * 100).toString(),
  //       color: COLORS[Math.round(Math.random() * (COLORS.length - 1))]
  //     };
  // }

  getHumanTasks() {





  }

}
