
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
@Injectable()
export class SharedService {
  headerData = new HttpHeaders({ 'Content-Type': 'application/json' });
  Authheaders = new HttpHeaders({ 'Authorization': 'Bearer ' + localStorage.getItem("accessToken") });
  tokenDataSource = new BehaviorSubject<any>(null);
  scrollSource = new BehaviorSubject<any>(null);
  isLoadingSource = new Subject<boolean>();

  constructor(private http: HttpClient) { }


  show() {
    this.isLoadingSource.next(true);
  }
  hide() {
    this.isLoadingSource.next(false);

  }
}

