import { AppConsts } from '@shared/AppConsts';
import * as rtlDetect from 'rtl-detect';
import { StyleLoaderService } from '@shared/utils/style-loader.service';
import { ThemeHelper } from '@app/shared/layout/themes/ThemeHelper';
import * as _ from 'lodash';
import { ThemeAssetContributorFactory } from './ThemeAssetContributorFactory';

export class DynamicResourcesHelper {

    static loadResources(callback: () => void): void {
        Promise.all([DynamicResourcesHelper.loadStyles()])
            .then(() => {
                callback();
            });
    }

    static loadStyles(): Promise<any> {
        let theme = ThemeHelper.getTheme();

        const isRtl = rtlDetect.isRtlLang(abp.localization.currentLanguage.name);

        if (isRtl) {
            document.documentElement.setAttribute('dir', 'rtl');
        }

        const cssPostfix = isRtl ? '-rtl' : '';
        const styleLoaderService = new StyleLoaderService();

        let styleUrls = [
            AppConsts.appBaseUrl + '/assets/metronic/themes/' + theme + '/css/style.bundle' + cssPostfix.replace('-', '.') + '.css',
            AppConsts.appBaseUrl + '/assets/primeng/datatable/css/primeng.datatable' + cssPostfix + '.css',
            AppConsts.appBaseUrl + '/assets/common/styles/metronic-customize.css',
            AppConsts.appBaseUrl + '/assets/common/styles/themes/' + theme + '/metronic-customize.css',
            AppConsts.appBaseUrl + '/assets/common/styles/metronic-customize-angular.css',

            //================

            //         <link rel="stylesheet" type="style/css" href="../../build/rappid.css">
            // <link rel="stylesheet" type="style/css" href="css/style.css">
            // <link rel="stylesheet" type="style/css" href="css/theme-picker.css">

            // <!-- theme-specific application CSS  -->
            // <link rel="stylesheet" type="style/css" href="css/style.dark.css">
            // <link rel="stylesheet" type="style/css" href="css/style.material.css">
            // <link rel="stylesheet" type="style/css" href="css/style.modern.css">
            // ../../../rappid/apps/KitchenSink/css/theme-picker.css
            // AppConsts.appBaseUrl + '/rappid/build/rappid.css',
            // AppConsts.appBaseUrl + '/src/styles.css',
            // AppConsts.appBaseUrl + '/src/assets/css/custom.css',
            // AppConsts.appBaseUrl + '/rappid/apps/KitchenSink/css/theme-picker.css',
            // AppConsts.appBaseUrl + '/rappid/apps/KitchenSink/css/style.css',
            // AppConsts.appBaseUrl + '/rappid/apps/KitchenSink/css/style.dark.css',
            // AppConsts.appBaseUrl + '/rappid/apps/KitchenSink/css/style.material.css',
            // AppConsts.appBaseUrl + '/rappid/apps/KitchenSink/css/style.modern.css',



            //===========
        ].concat(DynamicResourcesHelper.getAdditionalThemeAssets());

        styleLoaderService.loadArray(styleUrls);

        if (isRtl) {
            styleLoaderService.load(
                AppConsts.appBaseUrl + '/assets/common/styles/abp-zero-template-rtl.css'
            );
        }

        return Promise.resolve(true);
    }

    static getAdditionalThemeAssets(): string[] {
        let assetContributor = ThemeAssetContributorFactory.getCurrent();
        if (!assetContributor) {
            return [];
        }

        return assetContributor.getAssetUrls();
    }
}
