import { ChangeDetectorRef, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appTrim]',
  host: {'(blur)': 'onChange($event)'}
})
export class TrimDirective {

  constructor(
    private cdRef:ChangeDetectorRef) { }

onChange($event:any) {

    let theEvent = $event || window.event;
    theEvent.target.value = theEvent.target.value.trim();
  }


}
