import { Router } from '@angular/router';
import { JwtToken } from './account/login/jwtToken.model';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Component, Injector, OnInit } from '@angular/core';
import { NgxSpinnerTextService } from '@app/shared/ngx-spinner-text.service';
import { LocalStorageService } from '@shared/utils/local-storage.service';
import { TokenService, PermissionCheckerService, AbpSessionService } from 'abp-ng2-module';
import { AppConsts } from '@shared/AppConsts';

@Component({
    selector: 'app-root',
    template: `<router-outlet></router-outlet>
    <ngx-spinner type="ball-clip-rotate" size="medium" color="#5ba7ea">
        <p>{{ngxSpinnerText.currentText}}</p>
    </ngx-spinner>
    `
})
export class RootComponent implements OnInit {
    ngxSpinnerText: NgxSpinnerTextService;
    checking: boolean = true;

    constructor(_ngxSpinnerText: NgxSpinnerTextService,
        private _oidcSecurityService: OidcSecurityService,
        private _localStorageService: LocalStorageService,
        private _tokenService: TokenService,
        private _sessionService: AbpSessionService,
        private _permissionChecker: PermissionCheckerService,
        private route: Router
    ) {
        this.ngxSpinnerText = _ngxSpinnerText;
    }

    ngOnInit() {
        var url = window.location.href.toString();
        if (url.includes('logout')) {
            this.route.navigate[("logout")];
        }
        else {
            this._oidcSecurityService.checkAuth().subscribe(isAuthenticated => {
                if (!isAuthenticated) {
                    if (!sessionStorage.getItem("requested_url")) {
                        sessionStorage.setItem("requested_url", window.location.href.toString());
                    }
                    this._oidcSecurityService.authorize();
                } else {
                    const self = this;
                    let sessionDetail = localStorage.getItem("client_authnResult");
                    // localStorage.setItem('client_userData',sessionStorage.getItem('client_userData'));
                    // localStorage.setItem('client_storageSilentRenewRunning',sessionStorage.getItem('client_storageSilentRenewRunning'));
                    // localStorage.setItem('client_authnResult',sessionStorage.getItem('client_authnResult'));
                    // localStorage.setItem('client_session_state',sessionStorage.getItem('client_session_state'));
                    // localStorage.setItem('client_access_token_expires_at',sessionStorage.getItem('client_access_token_expires_at'));
                    // localStorage.setItem('client_jwtKeys',sessionStorage.getItem('client_jwtKeys'));
                    // localStorage.setItem('client_authStateControl',sessionStorage.getItem('client_authStateControl'));
                    // localStorage.setItem('client_authWellKnownEndPoints',sessionStorage.getItem('client_authWellKnownEndPoints'));
                    // localStorage.setItem('client_codeVerifier',sessionStorage.getItem('client_codeVerifier'));
                    // localStorage.setItem('client_authzData',sessionStorage.getItem('client_authzData'));
                    // localStorage.setItem('client_authNonce',sessionStorage.getItem('client_authNonce'));
                    // sessionStorage.clear();

                    let authCokkie = abp.utils.getCookieValue("Abp.AuthToken");
                    this._localStorageService.getItem('enc_auth_token', function (err, value) {
                        if (sessionDetail && !value && !authCokkie) {
                            var accessToken = JSON.parse(sessionDetail);
                            let tokenExpireDate = undefined;
                            self._tokenService.setToken(
                                accessToken.access_token,
                                tokenExpireDate
                            );
                            let token: JwtToken = new JwtToken();
                            token.accessToken = accessToken.access_token;
                            var data = JSON.stringify(token);
                            self._localStorageService.setItem("enc_auth_token", data);
                            // location.href =  AppConsts.appBaseUrl;
                            let redirectUrl = sessionStorage.getItem("requested_url");
                            sessionStorage.removeItem("requested_url");
                            location.href = redirectUrl;

                        } else if (sessionDetail && value && !authCokkie) {
                            var accessToken = JSON.parse(sessionDetail);
                            let tokenExpireDate = undefined;
                            self._tokenService.setToken(
                                accessToken.access_token,
                                tokenExpireDate
                            );

                            let token: JwtToken = new JwtToken();
                            token.accessToken = accessToken.access_token;
                            var data = JSON.stringify(token);
                            self._localStorageService.setItem("enc_auth_token", data);

                            // location.href =  AppConsts.appBaseUrl;
                            let redirectUrl = sessionStorage.getItem("requested_url");
                            sessionStorage.removeItem("requested_url");
                            location.href = redirectUrl;
                        }
                    });
                }
            })
        }

    }
}
