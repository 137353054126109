<div class="card card-custom h-100">
  <div class="card-header align-items-center border-0">
    <h3 class="card-title align-items-start flex-column">
      <span class="font-weight-bolder text-dark">
        Age Of Pending Tasks
      </span>
    </h3>
  </div>
  <div class="card-body noTopPadd">
    <div class="row" style="max-height: 200px" *ngIf="QueuesList | async as list">
      <ng-select [items]="list" name="qlist" (clear)="selectedqueue('')" [clearable]="false"
        (change)="selectedqueue(selectedQueue.taskQueue)" bindLabel="taskQueue.name" placeholder="All Queues"
        [(ngModel)]="selectedQueue">
      </ng-select>

      <!-- [view]="view" -->
     
        <ngx-charts-bar-horizontal [view]="view" [scheme]="colorScheme" [results]="single" [gradient]="gradient"
          [xAxis]="showXAxis" [yAxis]="showYAxis" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
          [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" (select)="onSelect($event)" (activate)="onActivate($event)"
          (deactivate)="onDeactivate($event)">
        </ngx-charts-bar-horizontal>
      
      
    </div>
  </div>
</div>