import { TxWidgetServiceService } from './../../TextExtractor-Widget-Services/Tx-widget-service.service';
import { Component, OnInit, Injector } from '@angular/core';
import { WidgetComponentBase } from "../WidgetComponentBase";
import { Subject } from 'rxjs';

@Component({
    selector: 'tX-widget-age-of-completed-tasks',
    templateUrl: './widget-age-of-completed-tasks.component.html',
    styleUrls: ['./widget-age-of-completed-tasks.component.css']
})
export class WidgetAgeOfCompletedTasksComponent extends WidgetComponentBase implements OnInit {
    QueuesList: Subject<any> = new Subject();
    selectedQueue: any;
    single: any[

    ];
    view: any[] = [700, 300];

    // options
    showXAxis: boolean = true;
    showYAxis: boolean = true;
    gradient: boolean = false;
    showLegend: boolean = true;
    showXAxisLabel: boolean = true;
    below: 'below'
    yAxisLabel: string = 'Days';
    showYAxisLabel: boolean = true;
    xAxisLabel: string = 'Number of Tasks';

    colorScheme = {
        domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
    };
    onSelect(data): void {
    }

    onActivate(data): void {
    }

    onDeactivate(data): void {
    }
    constructor(injector: Injector,
        private widgetservice: TxWidgetServiceService
    ) {
        super(injector);
        this.single = [];
    }

    ngOnInit() {
        this.getAllRequiredLookUps();
    }
    getAllRequiredLookUps() {
        this.widgetservice.GetAllLookUpsForHumanTaskPage().subscribe(
            res => {
                this.QueuesList.next(res.result.items);
                // this.selectedQueue = {
                //   taskQueue: {
                //     id: res.result.items[0].taskQueue.id,
                //     name: res.result.items[0].taskQueue.name
                //   }
                // }
                this.GetAgeOfCompletedTasks('');


            }
        )
    }


    selectedqueue(selqueuename: any) {
        this.GetAgeOfCompletedTasks(selqueuename.id);
    }

    GetAgeOfCompletedTasks(id) {
        if (id != '') {
            id = "?taskQueueIds=" + id;
        }
        this.widgetservice.GetAgeOfCompletedTasks(id).subscribe(
            res => {
                this.single = res.result;
            }
        );


    }

}
