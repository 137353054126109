<div class="card card-custom h-100">
    <div class="card-header align-items-center border-0">
        <h3 class="card-title align-items-start flex-column">
            <span class="font-weight-bolder text-dark">
                Organization Status
            </span>
        </h3>
    </div>
    <ngx-charts-line-chart [view]="view" [scheme]="colorScheme" [results]="single" [gradient]="gradient"
        [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel"
        [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel">
    </ngx-charts-line-chart>
</div>
